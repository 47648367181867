import { trackEvent } from "../services/AnalyticsService";
import { QuotesResponseDTO } from "./QuoteDTO";
import { ManualQuoteRequestDTO } from "./ManualQuoteRequestDTO";
import { OrderPayloadDTO, orderPayloadSchema } from "./OrderDTO";

type Event =
  | {
      name: "Customer Copied ID";
      type: "Order" | "Request";
    }
  | {
      name: "Customer Got Instant Quote";
      quotes: QuotesResponseDTO;
    }
  | {
      name: "Customer Ordered Transport";
    }
  | {
      name: "Customer Requested Quote";
      manualQuote: ManualQuoteRequestDTO;
    }
  | {
      name: "Customer Proceeded to Payment";
      orderDetails: OrderPayloadDTO;
    };

function getTransporterName() {
  const { hostname } = window.location;

  if (hostname === "localhost") {
    return "Test";
  }

  return hostname.replace(".superdispatch.page", "").replace("staging.", "");
}

function isManualQuote() {
  const searchParams = new URLSearchParams(location.search);
  return !!searchParams.get("quote_request_guid");
}

export function trackOrderTransportEvents(event: Event) {
  switch (event.name) {
    case "Customer Got Instant Quote": {
      const {
        name,
        quotes: { quote_request },
      } = event;
      trackEvent(name, {
        vehicle_inop_count: quote_request.vehicles.filter(
          (x) => x.is_inoperable
        ).length,
        vehicle_count: quote_request.vehicles.length,
        transporter_name: getTransporterName(),
      });
      break;
    }
    case "Customer Ordered Transport": {
      trackEvent(event.name, {
        quote_type: isManualQuote() ? "Manual" : "Instant",
        transporter_name: getTransporterName(),
      });
      break;
    }
    case "Customer Proceeded to Payment": {
      trackEvent(event.name, {
        quote_type: isManualQuote() ? "Manual" : "Instant",
        transporter_name: getTransporterName(),
        attachment_count: event.orderDetails.attachments?.length,
      });
      break;
    }
    case "Customer Requested Quote": {
      trackEvent(event.name, {
        transporter_name: getTransporterName(),
        has_vehicles_notes: !!event.manualQuote.vehicles_notes?.length,
      });
      break;
    }
    default: {
      const { name, ...rest } = event;
      trackEvent(event.name, rest);
    }
  }
}
