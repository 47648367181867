import { useIsMounted } from "@superdispatch/hooks";
import clipboardCopy from "clipboard-copy";
import { useCallback, useMemo, useState } from "react";

export interface UseClipboardResult {
  reset: () => void;
  copy: (text: string) => void;
  status: "waiting" | "copying" | "copied";
}

export function useClipboard(): UseClipboardResult {
  const isMounted = useIsMounted();
  const [status, setStatus] = useState<UseClipboardResult["status"]>("waiting");
  const reset = useCallback(() => {
    setStatus("waiting");
  }, []);
  const copy = useCallback(
    (text: string) => {
      setStatus("copying");

      clipboardCopy(text).then(
        () => {
          if (isMounted()) {
            setStatus("copied");
          }
        },
        () => {
          if (isMounted()) {
            setStatus("waiting");
            alert(text);
          }
        }
      );
    },
    [isMounted]
  );

  return useMemo<UseClipboardResult>(
    () => ({ copy, reset, status }),
    [reset, copy, status]
  );
}

export function getCook(name: string) {
  const cookie = RegExp(name + "=[^;]+").exec(document.cookie);
  return decodeURIComponent(
    !!cookie ? cookie.toString().replace(/^[^=]+./, "") : ""
  );
}
