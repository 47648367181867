import styled from "styled-components";
import { Link } from "@material-ui/core";
import { Color, Stack } from "@superdispatch/ui";
import { TextBox } from "@superdispatch/ui-lab";
import { Link as RouterLink } from "react-router-dom";

const Container = styled.div`
  flex: 1;
  max-width: 800px;
`;

const Content = styled.div`
  padding: 32px;

  background-color: #fff;
  border-radius: 3px;
  border: 1px solid ${Color.Silver400};
`;

export function QuotePaidPage() {
  return (
    <Container>
      <Content>
        <Stack space="small" align="center">
          <TextBox variant="heading-2">Order Booked</TextBox>

          <TextBox align="center">
            You've already booked your order using this quote.
          </TextBox>

          <Link color="primary" to="/" component={RouterLink}>
            Start Another Order
          </Link>
        </Stack>
      </Content>
    </Container>
  );
}
