import {
  createHTTP,
  HTTPEndpointInput,
  HTTPError,
  HTTPRequestOptions,
} from "@superdispatch/http";
import { URITemplateParams } from "@superdispatch/uri";
import { Schema } from "yup";
import { useQuery } from "react-query";
import { TransporterDTO, transporterSchema } from "./TransporterDTO";
import { useMemo } from "react";
import { QuoteRequestDTO } from "./QuoteRequestDTO";
import { QuotesResponseDTO, quotesResponseSchema } from "./QuoteDTO";
import {
  OrderDTO,
  OrderPayloadDTO,
  orderSchema,
  OrderSubmitResponseDTO,
  PaymentCheckoutResponseDTO,
} from "./OrderDTO";
import {
  ManualQuoteRequestDTO,
  ManualQuoteResponseDTO,
  manualQuoteResponseSchema,
} from "./ManualQuoteRequestDTO";
import { set } from "lodash-es";

interface APISuccessResponse<Data> {
  meta: {
    status: "success";
  };
  data: Data;
}

interface APIErrorResponse {
  meta: { status: "error" };
  message: string;
}

interface APIFailureResponse {
  meta: {
    status: "fail";
  };
  data: {
    type: string;
    message: string;
    details: [
      {
        loc?: string[];
        message: string;
        type: string | null;
      }
    ];
  };
}

type APIError = Error & APIFailureResponse["data"];

export function isAPIError(error: unknown): error is APIError {
  return error instanceof Error && "details" in error;
}

function getTestTransporterSite() {
  return localStorage.getItem("TEST_TRANSPORTER_SITE");
}

const http = createHTTP({
  baseURL:
    import.meta.env.VITE_APP_TARGET === "production"
      ? "https://crm.superdispatch.com"
      : "https://staging.crm.superdispatch.org",
  headers: (headers = {}) => {
    const testTransporterSite = getTestTransporterSite();

    if (
      testTransporterSite &&
      import.meta.env.VITE_APP_TARGET !== "production"
    ) {
      headers["X-Test-Transporter-Site"] = testTransporterSite;
    }

    return headers;
  },
});

export function requestJSON<
  TData,
  TParams extends URITemplateParams = URITemplateParams
>(
  input: HTTPEndpointInput<TParams>,
  options?: HTTPRequestOptions & { schema?: Schema }
): Promise<TData> {
  return http
    .requestJSON<APISuccessResponse<TData>>(input, options)
    .then(({ data }) => {
      if (options?.schema) {
        return options.schema.cast(data);
      }
      return data;
    })
    .catch((error) => {
      if (error instanceof HTTPError) {
        return error.response
          .json()
          .then((response: APIFailureResponse | APIErrorResponse) => {
            if ("data" in response) {
              const error = new Error(response.data.message);
              throw Object.assign(error, response.data);
            }

            throw new Error(response.message);
          });
      }

      throw new Error(error.message || "Failed to fetch");
    });
}

export function useTransporterProfile() {
  return useQuery(
    ["transporter", "profile"],
    () =>
      requestJSON<TransporterDTO>("GET /api/order-transport/shipper", {
        schema: transporterSchema,
      }),
    { staleTime: Infinity, cacheTime: Infinity }
  );
}

export function useOrder(guid: string | null) {
  return useQuery<OrderDTO, Error>(
    ["order", { guid }],
    () =>
      requestJSON<OrderDTO>([
        "GET /api/order-transport/order/{guid}",
        { guid },
      ]).then((response) => {
        // Set fields to make compatible with OrderDTO
        set(response, "guid", guid);
        set(response, "quote_guid", response.quote.guid);

        return orderSchema.cast(response);
      }),
    { enabled: !!guid, staleTime: Infinity, cacheTime: Infinity }
  );
}

export function useQuotes(quoteRequestGuid: string | null) {
  return useQuery<QuotesResponseDTO, Error>(
    ["quotes", { quoteRequestGuid }],
    () =>
      requestJSON<QuotesResponseDTO>([
        "GET /api/order-transport/quote-request/{guid}",
        { guid: quoteRequestGuid },
      ]).then((response) => {
        return quotesResponseSchema.cast(response);
      }),
    { enabled: !!quoteRequestGuid, staleTime: Infinity, cacheTime: Infinity }
  );
}

export function useQuoteAPI() {
  return useMemo(() => {
    return {
      submitOrder(orderDetails: OrderPayloadDTO) {
        return requestJSON<OrderSubmitResponseDTO>(
          "POST /api/order-transport/order",
          { json: orderDetails }
        );
      },
      updateOrder(guid: string, orderDetails: OrderPayloadDTO) {
        return requestJSON<OrderSubmitResponseDTO>(
          ["PUT /api/order-transport/order/{guid}", { guid }],
          { json: orderDetails }
        );
      },
      paymentCheckout(orderGUID: string) {
        return requestJSON<PaymentCheckoutResponseDTO>(
          `POST /api/order-transport/order/${orderGUID}/payment/checkout`
        );
      },
      requestQuotes: (quoteRequest: QuoteRequestDTO) =>
        requestJSON<QuotesResponseDTO>("POST /api/order-transport/quote", {
          json: quoteRequest,
          schema: quotesResponseSchema,
        }),
      requestManualQuotes: (
        quoteRequestGuid: string,
        payload: ManualQuoteRequestDTO
      ) =>
        requestJSON<ManualQuoteResponseDTO>(
          [
            "POST /api/order-transport/quote-request/{guid}/manual-quote",
            {
              guid: quoteRequestGuid,
            },
          ],
          {
            json: payload,
            schema: manualQuoteResponseSchema,
          }
        ),
    };
  }, []);
}
