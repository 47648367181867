import { Link, Tooltip } from "@material-ui/core";
import { DescriptionItem, TextBox } from "@superdispatch/ui-lab";
import { Column, Columns, Inline, Stack } from "@superdispatch/ui";
import styled from "styled-components";
import { PickupAltIcon } from "../../icons/PickupAltIcon";
import { DeliveryAltIcon } from "../../icons/DeliveryAltIcon";
import {
  QuoteRequestDTO,
  QuoteRequestVenueDTO,
} from "../../data/QuoteRequestDTO";
import { joinStrings } from "shared/utils/StringUtils";
import { DirectionsCar } from "@material-ui/icons";
import { NonDrivableCar } from "../../icons/NonDrivableCarIcon";

const PointerLink = styled(Link)`
  cursor: pointer;
`;

const VenuesContainer = styled.div`
  display: grid;
  gap: 16px 8px;

  @media (min-width: 900px) {
    grid-template-columns: repeat(2, auto);
  }
`;

function formatAddress({ zip, city, state, address }: QuoteRequestVenueDTO) {
  return joinStrings(
    ", ",
    address,
    city,
    joinStrings(" ", state?.toUpperCase(), zip)
  );
}

interface Props {
  onEdit: () => void;
  quoteRequest: QuoteRequestDTO;
}

export function TransportDetailsView({ quoteRequest, onEdit }: Props) {
  return (
    <Stack>
      <Columns>
        <Column>
          <TextBox variant="heading-3">Transport Details</TextBox>
        </Column>

        <Column width="content">
          <PointerLink onClick={onEdit}>Change</PointerLink>
        </Column>
      </Columns>

      <Stack space="small">
        <VenuesContainer>
          <DescriptionItem icon={<PickupAltIcon />}>
            {formatAddress(quoteRequest.pickup)}
          </DescriptionItem>

          <DescriptionItem icon={<DeliveryAltIcon />}>
            {formatAddress(quoteRequest.delivery)}
          </DescriptionItem>
        </VenuesContainer>

        <Inline space="xsmall">
          <DirectionsCar fontSize="small" color="action" />

          <Stack>
            {quoteRequest.vehicles.map(
              ({ vin, year, make, model, is_inoperable }) => {
                const hasVehicleDetails = !!year || !!make || !!model;

                if (!hasVehicleDetails) {
                  return (
                    <Inline key={vin} space="xxsmall" verticalAlign="center">
                      <TextBox aria-label="vin">{vin}</TextBox>

                      {is_inoperable && (
                        <Tooltip title="Non-drivable">
                          <NonDrivableCar fontSize="small" color="action" />
                        </Tooltip>
                      )}
                    </Inline>
                  );
                }

                return (
                  <Stack key={vin} space="none">
                    <Inline space="xxsmall" verticalAlign="center">
                      <TextBox>{joinStrings(" ", year, make, model)}</TextBox>

                      {is_inoperable && (
                        <Tooltip title="Non-drivable">
                          <NonDrivableCar fontSize="small" color="action" />
                        </Tooltip>
                      )}
                    </Inline>

                    <TextBox color="secondary" aria-label="vin">
                      {vin}
                    </TextBox>
                  </Stack>
                );
              }
            )}
          </Stack>
        </Inline>
      </Stack>
    </Stack>
  );
}
