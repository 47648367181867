import { ReactNode } from "react";
import { logError } from "../services/MonitoringService";

export function formatNumber(
  value: null | number | string | undefined,
  options?: Intl.NumberFormatOptions
): string {
  if (value == null) value = 0;
  if (typeof value == "string") value = parseFloat(value);
  if (Number.isFinite(value)) {
    try {
      return value.toLocaleString("en-US", options);
    } catch (error: unknown) {
      logError(error, "formatNumber", { value, options });
    }
  }

  return String(value);
}

type FormatCurrencyOptions = Pick<
  Intl.NumberFormatOptions,
  "minimumFractionDigits" | "maximumFractionDigits"
>;

export function formatCurrency(
  value: number | string | null | undefined,
  {
    maximumFractionDigits,
    minimumFractionDigits = 0,
  }: FormatCurrencyOptions = {}
): string {
  // Handle RangeError error
  // https://stackoverflow.com/questions/41045270/range-error-with-tolocalestring-with-maximumnumber-of-digits-0
  if (maximumFractionDigits != null) {
    minimumFractionDigits = Math.min(
      maximumFractionDigits,
      minimumFractionDigits
    );
  }

  return formatNumber(value, {
    style: "currency",
    currency: "USD",
    minimumFractionDigits,
    maximumFractionDigits,
  });
}

export function formatPlural<TOne extends ReactNode, TOther extends ReactNode>(
  value: null | number | undefined,
  one: TOne,
  other: TOther
): TOne | TOther {
  return value === 1 || value === -1 ? one : other;
}
