import { createSvgIcon } from "shared/utils/IconUtils";

export const NonDrivableCar = createSvgIcon(
  "EnclosedTrailer",
  <>
    <path
      d="M4 12V12.3334C4 12.6111 3.90278 12.8472 3.70833 13.0417C3.51389 13.2361 3.27778 13.3334 3 13.3334C2.72222 13.3334 2.48611 13.2361 2.29167 13.0417C2.09722 12.8472 2 12.6111 2 12.3334V7.56669C2 7.48891 2.00556 7.41113 2.01667 7.33335C2.02778 7.25558 2.04444 7.18335 2.06667 7.11669L3.31667 3.56669C3.40556 3.30002 3.56667 3.08335 3.8 2.91669C4.03333 2.75002 4.29444 2.66669 4.58333 2.66669H7.53333C7.46667 2.8778 7.41667 3.09446 7.38333 3.31669C7.35 3.53891 7.33333 3.76669 7.33333 4.00002H4.56667L3.86667 6.00002H7.78333C8.02778 6.53335 8.36944 6.99724 8.80833 7.39169C9.24722 7.78613 9.73333 8.09446 10.2667 8.31669C10.1778 8.41669 10.1111 8.52502 10.0667 8.64169C10.0222 8.75835 10 8.8778 10 9.00002C10 9.2778 10.0972 9.51391 10.2917 9.70835C10.4861 9.9028 10.7222 10 11 10C11.2778 10 11.5139 9.9028 11.7083 9.70835C11.9028 9.51391 12 9.2778 12 9.00002C12 8.94446 11.9972 8.88891 11.9917 8.83335C11.9861 8.7778 11.9722 8.72224 11.95 8.66669C12.3056 8.66669 12.6556 8.63058 13 8.55835C13.3444 8.48613 13.6778 8.37224 14 8.21669V12.3334C14 12.6111 13.9028 12.8472 13.7083 13.0417C13.5139 13.2361 13.2778 13.3334 13 13.3334C12.7222 13.3334 12.4861 13.2361 12.2917 13.0417C12.0972 12.8472 12 12.6111 12 12.3334V12H4ZM5 10C5.27778 10 5.51389 9.9028 5.70833 9.70835C5.90278 9.51391 6 9.2778 6 9.00002C6 8.72224 5.90278 8.48613 5.70833 8.29169C5.51389 8.09724 5.27778 8.00002 5 8.00002C4.72222 8.00002 4.48611 8.09724 4.29167 8.29169C4.09722 8.48613 4 8.72224 4 9.00002C4 9.2778 4.09722 9.51391 4.29167 9.70835C4.48611 9.9028 4.72222 10 5 10Z"
      fill="#8F949E"
    />
    <path
      d="M10.9165 7.33335C10.8177 7.33335 10.7236 7.31484 10.6341 7.2778C10.5446 7.24076 10.4659 7.18829 10.398 7.12039L8.87947 5.60187C8.81156 5.53397 8.7591 5.45527 8.72206 5.36576C8.68502 5.27626 8.6665 5.18212 8.6665 5.08335V2.91669C8.6665 2.81792 8.68502 2.72379 8.72206 2.63428C8.7591 2.54477 8.81156 2.46607 8.87947 2.39817L10.398 0.87965C10.4659 0.811749 10.5446 0.75928 10.6341 0.722243C10.7236 0.685206 10.8177 0.666687 10.9165 0.666687H13.0832C13.1819 0.666687 13.2761 0.685206 13.3656 0.722243C13.4551 0.75928 13.5338 0.811749 13.6017 0.87965L15.1202 2.39817C15.1881 2.46607 15.2406 2.54477 15.2776 2.63428C15.3147 2.72379 15.3332 2.81792 15.3332 2.91669V5.08335C15.3332 5.18212 15.3147 5.27626 15.2776 5.36576C15.2406 5.45527 15.1881 5.53397 15.1202 5.60187L13.6017 7.12039C13.5338 7.18829 13.4551 7.24076 13.3656 7.2778C13.2761 7.31484 13.1819 7.33335 13.0832 7.33335H10.9165ZM11.9998 4.51854L12.7961 5.31484C12.864 5.38274 12.9505 5.41669 13.0554 5.41669C13.1603 5.41669 13.2467 5.38274 13.3147 5.31484C13.3826 5.24693 13.4165 5.16051 13.4165 5.05558C13.4165 4.95064 13.3826 4.86422 13.3147 4.79632L12.5184 4.00002L13.3147 3.20372C13.3826 3.13582 13.4165 3.0494 13.4165 2.94447C13.4165 2.83953 13.3826 2.75311 13.3147 2.68521C13.2467 2.6173 13.1603 2.58335 13.0554 2.58335C12.9505 2.58335 12.864 2.6173 12.7961 2.68521L11.9998 3.4815L11.2035 2.68521C11.1356 2.6173 11.0492 2.58335 10.9443 2.58335C10.8393 2.58335 10.7529 2.6173 10.685 2.68521C10.6171 2.75311 10.5832 2.83953 10.5832 2.94447C10.5832 3.0494 10.6171 3.13582 10.685 3.20372L11.4813 4.00002L10.685 4.79632C10.6171 4.86422 10.5832 4.95064 10.5832 5.05558C10.5832 5.16051 10.6171 5.24693 10.685 5.31484C10.7529 5.38274 10.8393 5.41669 10.9443 5.41669C11.0492 5.41669 11.1356 5.38274 11.2035 5.31484L11.9998 4.51854Z"
      fill="#8F949E"
    />
  </>,
  { viewBox: "0 0 16 16" }
);
