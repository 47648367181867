import { Link, LinkProps } from "@material-ui/core";
import { Color, Stack } from "@superdispatch/ui";
import { TextBox } from "@superdispatch/ui-lab";
import styled from "styled-components";
import { Link as RouterLink, useSearchParams } from "react-router-dom";

const Container = styled.div`
  flex: 1;
  max-width: 800px;
`;

const Content = styled.div`
  padding: 32px;

  background-color: #fff;
  border-radius: 3px;
  border: 1px solid ${Color.Silver400};
`;

export function PaymentSucceedPage() {
  const [search] = useSearchParams();

  const email = search.get("email");
  const orderNumber = search.get("order_number");

  if (!email || !orderNumber) {
    throw new Error("Invalid Params");
  }

  return (
    <Container>
      <Content>
        <Stack space="small" align="center">
          <TextBox variant="heading-2">Congratulations!</TextBox>

          <TextBox align="center">
            You've successfully booked your shipping order. A confirmation email
            was sent to {email}. <br />
            Order ID: {orderNumber}.
          </TextBox>

          <Link color="primary" to="/" component={RouterLink}>
            Start Another Order
          </Link>
        </Stack>
      </Content>
    </Container>
  );
}
