import {
  CircularProgress,
  InputAdornment,
  makeStyles,
  StandardTextFieldProps,
  TextField,
} from "@material-ui/core";
import { Autocomplete, AutocompleteProps } from "@material-ui/lab";
import React, { forwardRef } from "react";
import { Geocoding } from "../services/geo/GeoHelpers";
import { useGeoFieldProps, UseGeoFieldPropsOptions } from "./MapboxGeoField";

const useStyles = makeStyles(() => ({
  inputRoot: {
    flexWrap: "nowrap",
  },
}));

interface GeoFieldCommonProps
  extends Omit<
    AutocompleteProps<Geocoding, true, true, false>,
    "renderInput" | "defaultValue" | "value"
  > {
  TextFieldProps?: StandardTextFieldProps;
}

export interface GeoFieldSingleProps
  extends Omit<GeoFieldCommonProps, "options" | "onChange" | "getOptionLabel">,
    UseGeoFieldPropsOptions {
  value: Geocoding | null;
  defaultValue?: Geocoding;
  onChange?: (value: Geocoding | null) => void;
  TextFieldProps?: StandardTextFieldProps;
}

export const GeoField = forwardRef(
  (
    {
      provider,
      value,
      types,
      onChange,
      onBlur,
      TextFieldProps,
      formatOptionLabel,
      ...props
    }: GeoFieldSingleProps,
    ref
  ) => {
    const classes = useStyles();
    const autocompleteProps = useGeoFieldProps({
      provider,
      types,
      formatOptionLabel,
    });
    const { options } = autocompleteProps;
    return (
      <Autocomplete
        {...props}
        {...autocompleteProps}
        ref={ref}
        classes={classes}
        value={value}
        multiple={false}
        disableClearable={false}
        onBlur={(event) => {
          onBlur?.(event);
          const mostRelevant = options.find(item => item.relevance === 1);
          if (!value && mostRelevant) {
            onChange?.(mostRelevant);
          }
        }}
        onChange={(_, selectedValue) => {
          onChange?.(selectedValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...TextFieldProps}
            inputProps={{
              ...params.inputProps,
              ...TextFieldProps?.inputProps,
              maxLength: provider === "mapbox" ? 20 : 50,
            }}
            InputProps={{
              ...params.InputProps,
              ...TextFieldProps?.InputProps,
              endAdornment: autocompleteProps.loading ? (
                <InputAdornment position="end">
                  <CircularProgress color="inherit" size={20} />
                </InputAdornment>
              ) : (
                params.InputProps.endAdornment
              ),
            }}
          />
        )}
      />
    );
  }
);
