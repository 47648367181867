import { QuoteDTO, QuotesResponseDTO } from "../../data/QuoteDTO";
import { Button, TextBox } from "@superdispatch/ui-lab";
import { Stack } from "@superdispatch/ui";
import { formatCurrency } from "shared/helpers/IntlHelpers";
import { trackOrderTransportEvents } from "../../data/OrderTransportAnalytics";

interface Props {
  quotes: QuotesResponseDTO;
  onSubmit: (quote: QuoteDTO) => void;
}

export function TransportOptionsForm({ quotes, onSubmit }: Props) {
  if (quotes.quotes.length > 1) {
    return (
      <Stack space="small">
        <TextBox variant="heading-2">Transport Option</TextBox>

        <TextBox>Multiple quotes are not supported yet.</TextBox>
      </Stack>
    );
  }

  if (quotes.quotes.length === 0) {
    return (
      <Stack space="small">
        <TextBox variant="heading-2">Transport Option</TextBox>

        <TextBox>No quotes available. Please try again</TextBox>
      </Stack>
    );
  }

  const [quote] = quotes.quotes;

  return (
    <Stack space="small">
      <TextBox variant="heading-2">Transport Option</TextBox>

      <Stack>
        <TextBox variant="heading-1">{formatCurrency(quote.price)}</TextBox>
        <TextBox>
          Pickup within 7 business days of your requested ship date. Open
          trailer only.
        </TextBox>
      </Stack>

      <Button
        onClick={() => {
          trackOrderTransportEvents({ name: "Customer Ordered Transport" });
          onSubmit(quote);
        }}
      >
        Order Transport
      </Button>
    </Stack>
  );
}
