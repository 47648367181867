import { Form, FormikProvider } from "formik";
import { useMemo } from "react";
import { TextBox } from "@superdispatch/ui-lab";
import {
  Button,
  Column,
  Columns,
  Inline,
  Stack,
  useSnackbarStack,
} from "@superdispatch/ui";
import {
  FormikMaxLengthTextField,
  FormikTextField,
  useFormikEnhanced,
} from "@superdispatch/forms";
import { useQuoteAPI } from "../../data/OrderTransportAPI";
import {
  ManualQuoteRequestDTO,
  ManualQuoteResponseDTO,
  manualQuoteRequestSchema,
} from "../../data/ManualQuoteRequestDTO";
import { useNavigate } from "react-router-dom";
import {
  addLogContext,
  logInfo,
  logWarning,
} from "shared/services/MonitoringService";
import { trackOrderTransportEvents } from "../../data/OrderTransportAnalytics";
import { formatQuotingServiceErrorMessage } from "../../data/QuotingServiceError";

interface Props {
  quoteRequestGuid: string;
}

export function ManualQuoteForm({ quoteRequestGuid }: Props) {
  const navigate = useNavigate();
  const { requestManualQuotes } = useQuoteAPI();
  const { addSnackbar } = useSnackbarStack();

  const initialValues = useMemo<ManualQuoteRequestDTO>(
    () => ({
      contact_name: "",
      company_name: "",
      email: "",
      phone: "",
      vehicles_notes: null,
    }),
    [quoteRequestGuid]
  );

  const formik = useFormikEnhanced<
    ManualQuoteRequestDTO,
    ManualQuoteResponseDTO
  >({
    initialValues,
    enableReinitialize: false,
    validationSchema: manualQuoteRequestSchema,
    onSubmit: (values) => {
      addLogContext("manual_quote", values);
      return requestManualQuotes(quoteRequestGuid, values);
    },
    onSubmitSuccess(response, values) {
      trackOrderTransportEvents({
        name: "Customer Requested Quote",
        manualQuote: values,
      });
      logInfo("Submitted Manual quote");
      navigate(
        `/quote-requested?email=${response.email}&quote_request_guid=${response.guid}`
      );
    },
    onSubmitFailure(error) {
      logWarning("Failed to submit manual quote", { error });
      addSnackbar(
        formatQuotingServiceErrorMessage(error) || "Failed to get quote",
        {
          variant: "error",
        }
      );
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <Stack space="small">
          <TextBox variant="heading-2">Transport Option</TextBox>

          <Stack space="xsmall">
            <TextBox variant="heading-1">Quote within 24h</TextBox>

            <Stack space="medium">
              <TextBox>
                Due to vehicle details, the quote cannot be generated. Leave
                your contact details and our team will come back to you with a
                custom quote for your order.
              </TextBox>

              <Columns space="small" collapseBelow="tablet">
                <Column>
                  <FormikTextField
                    name="company_name"
                    label="Company Name"
                    fullWidth={true}
                  />
                </Column>

                <Column>
                  <FormikTextField
                    name="contact_name"
                    label="Contact Name"
                    fullWidth={true}
                  />
                </Column>

                <Column>
                  <FormikTextField
                    name="email"
                    label="Email"
                    fullWidth={true}
                  />
                </Column>

                <Column>
                  <FormikTextField
                    name="phone"
                    label="Phone"
                    fullWidth={true}
                  />
                </Column>
              </Columns>

              <Columns>
                <Column>
                  <FormikMaxLengthTextField
                    placeholder="Enter additional information about the vehicle(s) to ensure an accurate quote is provided."
                    fullWidth={true}
                    multiline={true}
                    minRows={2}
                    maxLength={200}
                    name="vehicles_notes"
                    label={
                      <Inline space="xxsmall">
                        <TextBox>Vehicle Notes</TextBox>
                        <TextBox color="secondary">(Optional)</TextBox>
                      </Inline>
                    }
                  />
                </Column>
              </Columns>

              <Button
                type="submit"
                color="primary"
                variant="contained"
                isLoading={formik.isSubmitting}
              >
                Request Quote
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
