import { Form, FormikProvider } from "formik";
import { InputAdornment } from "@material-ui/core";
import { useMemo } from "react";
import { TextBox } from "@superdispatch/ui-lab";
import { Button, Color, Stack, useSnackbarStack } from "@superdispatch/ui";
import { useFormikEnhanced } from "@superdispatch/forms";
import styled from "styled-components";
import { VenueField } from "./VenueField";
import { PickupAltIcon } from "../../icons/PickupAltIcon";
import { DeliveryAltIcon } from "../../icons/DeliveryAltIcon";
import { VehiclesField } from "./VehiclesField";
import {
  QuoteRequestDTO,
  quoteRequestSchema,
} from "../../data/QuoteRequestDTO";
import { isAPIError, useQuoteAPI } from "../../data/OrderTransportAPI";
import { QuotesResponseDTO } from "../../data/QuoteDTO";
import {
  addLogContext,
  logInfo,
  logWarning,
} from "shared/services/MonitoringService";
import { trackOrderTransportEvents } from "../../data/OrderTransportAnalytics";
import { formatQuotingServiceErrorMessage } from "../../data/QuotingServiceError";

const VenuesContainer = styled.div`
  display: grid;
  gap: 16px 8px;

  @media (min-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

interface Props {
  quoteRequest: QuoteRequestDTO | null;
  onSubmitSuccess: (quotes: QuotesResponseDTO) => void;
}

export function TransportDetailsForm({ quoteRequest, onSubmitSuccess }: Props) {
  const { requestQuotes } = useQuoteAPI();
  const { addSnackbar } = useSnackbarStack();

  const initialValues = useMemo(
    () =>
      quoteRequest ||
      ({
        pickup: null,
        delivery: null,
        vehicles: [{ vin: "", is_inoperable: false }],
      } as unknown as QuoteRequestDTO),
    []
  );

  const formik = useFormikEnhanced<QuoteRequestDTO, QuotesResponseDTO>({
    initialValues,
    enableReinitialize: false,
    validationSchema: quoteRequestSchema,
    onSubmit: (values) => {
      addLogContext("quote_request", values);
      return requestQuotes(values);
    },
    onSubmitSuccess(response) {
      onSubmitSuccess(response);
      logInfo("Submitted Quote", { response });
      trackOrderTransportEvents({
        name: "Customer Got Instant Quote",
        quotes: response,
      });
    },
    onSubmitFailure(error) {
      logWarning("Failed to submit quote", { error });
      addSnackbar(
        formatQuotingServiceErrorMessage(error) || "Failed to get quote",
        {
          variant: "error",
        }
      );
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <Stack space="large">
          <TextBox variant="heading-2">Transport Details</TextBox>

          <VenuesContainer>
            <VenueField
              name="pickup"
              TextFieldProps={{
                label: "Pickup Address",
                placeholder: "Enter Full Pickup Address",
                InputProps: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <PickupAltIcon htmlColor={Color.Yellow400} />
                    </InputAdornment>
                  ),
                },
              }}
            />

            <VenueField
              name="delivery"
              TextFieldProps={{
                label: "Delivery Address",
                placeholder: "Enter Full Delivery Address",
                InputProps: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <DeliveryAltIcon htmlColor={Color.Green300} />
                    </InputAdornment>
                  ),
                },
              }}
            />
          </VenuesContainer>

          <Stack space="large">
            <TextBox variant="heading-4">Vehicle</TextBox>

            <VehiclesField name="vehicles" />
          </Stack>

          <Button
            type="submit"
            color="primary"
            variant="contained"
            isLoading={formik.isSubmitting}
          >
            {quoteRequest ? "Update Quote" : "Get Instant Quote"}
          </Button>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
