import { QuoteDTO, QuotesResponseDTO } from "../../data/QuoteDTO";
import { DescriptionItem, TextBox } from "@superdispatch/ui-lab";
import { Inline, Stack } from "@superdispatch/ui";
import { formatCurrency } from "shared/helpers/IntlHelpers";
import { MonetizationOn } from "@material-ui/icons";
import { TrailerIcon } from "../../icons/TrailerIcon";

interface Props {
  quotes: QuotesResponseDTO;
}

export function TransportOptionsView({ quotes }: Props) {
  if (quotes.quotes.length > 1) {
    return (
      <Stack space="small">
        <TextBox variant="heading-3">Transport Option</TextBox>

        <TextBox>Multiple quotes are not supported yet.</TextBox>
      </Stack>
    );
  }

  if (quotes.quotes.length === 0) {
    return (
      <Stack space="small">
        <TextBox variant="heading-3">Transport Option</TextBox>

        <TextBox>No quotes available. Please try again</TextBox>
      </Stack>
    );
  }

  const [quote] = quotes.quotes;

  return (
    <Stack>
      <TextBox variant="heading-3">Transport Option</TextBox>

      <Inline space="large">
        <DescriptionItem icon={<MonetizationOn />}>
          {formatCurrency(quote.price)}
        </DescriptionItem>

        <DescriptionItem icon={<TrailerIcon />}>Open Trailer</DescriptionItem>
      </Inline>
    </Stack>
  );
}
