import type {
  GeocodeFeature,
  GeocodeProperties,
} from "@mapbox/mapbox-sdk/services/geocoding";
import { Geocoding, GeocodingKey, GEOCODING_KEYS } from "./GeoHelpers";
import { get } from "lodash-es";
import { joinStrings } from "../../utils/StringUtils";

function findGeocodingKey(id: string): undefined | GeocodingKey {
  return GEOCODING_KEYS.find((key) => id.startsWith(key));
}

export function parseGeocodeFeature(geocodeFeature: GeocodeFeature): Geocoding {
  const features = [geocodeFeature];

  if (Array.isArray(geocodeFeature.context)) {
    features.push(...geocodeFeature.context);
  }

  const geocoding: Geocoding = {
    relevance: geocodeFeature.relevance,
    longitude: geocodeFeature.geometry.coordinates[0],
    latitude: geocodeFeature.geometry.coordinates[1],
  };

  for (const feature of features) {
    const geocodingKey = findGeocodingKey(feature.id);
    if (geocodingKey) {
      geocoding[geocodingKey] = feature.text;

      if (geocodingKey === "address") {
        geocoding.street = feature.text;
      }

      if (geocodingKey === "address" && feature.address) {
        /* House or building number comes in `address` property */
        geocoding.house_number = feature.address;
      }

      const properties =
        feature.type === "Feature"
          ? feature.properties
          : // `GeocodeFeature#context` should have type `GeocodeProperties[]`
            (feature as GeocodeProperties);

      if (properties.short_code) {
        if (geocodingKey === "country") {
          geocoding.country_short = properties.short_code;
        }

        if (geocodingKey === "region") {
          geocoding.region_short = properties.short_code.replace("US-", "");
        }
      }
    }

    geocoding.address = joinStrings(" ", geocoding?.house_number, geocoding?.street);
  }

  return geocoding;
}
