import { IconButton, Link, LinkProps, Tooltip } from "@material-ui/core";
import { Color, Inline, Stack } from "@superdispatch/ui";
import { TextBox } from "@superdispatch/ui-lab";
import styled from "styled-components";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { useClipboard } from "shared/helpers/DomHelpers";
import { ContentCopyIcon } from "../../icons/ContentCopyIcon";
import { trackOrderTransportEvents } from "../../data/OrderTransportAnalytics";

const Container = styled.div`
  flex: 1;
  max-width: 800px;
`;

const Content = styled.div`
  padding: 32px;

  background-color: #fff;
  border-radius: 3px;
  border: 1px solid ${Color.Silver400};
`;

export function QuoteRequestedPage() {
  const [search] = useSearchParams();
  const { copy, status, reset } = useClipboard();

  const email = search.get("email");
  const quoteRequestGuid = search.get("quote_request_guid");

  if (!email || !quoteRequestGuid) {
    throw new Error("Invalid Params");
  }

  return (
    <Container>
      <Content>
        <Stack space="small" align="center">
          <TextBox variant="heading-2">Quote Requested</TextBox>

          <TextBox align="center">
            Thank you! We will send a quote shortly to {email}.
          </TextBox>

          <Inline verticalAlign="center">
            <TextBox align="center">
              Your request number: <code>{quoteRequestGuid}</code>
            </TextBox>

            <Tooltip
              title={status === "copied" ? "Copied" : "Copy"}
              onClose={() => reset()}
            >
              <IconButton
                size="small"
                onClick={() => {
                  copy(quoteRequestGuid);
                  trackOrderTransportEvents({
                    name: "Customer Copied ID",
                    type: "Request",
                  });
                }}
              >
                <ContentCopyIcon fontSize="small" color="action" />
              </IconButton>
            </Tooltip>
          </Inline>

          <Link color="primary" to="/" component={RouterLink}>
            Start Another Order
          </Link>
        </Stack>
      </Content>
    </Container>
  );
}
