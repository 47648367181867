import { Color, Inline, Stack } from "@superdispatch/ui";
import { TextBox } from "@superdispatch/ui-lab";
import React from "react";
import { AppHeader } from "./AppHeader";
import styled from "styled-components";
import { useTransporterProfile } from "./data/OrderTransportAPI";
import PoweredBySD from "./assets/powered_by_sd.png";

const LogoLink = styled.a`
  color: ${Color.Dark500};
  text-decoration: none;
  display: flex;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 24px;
`;

const Footer = styled.footer`
  display: flex;
  justify-content: center;
  padding: 24px;
`;

interface Props {
  children: React.ReactNode;
}

export function AppLayout({ children }: Props) {
  const { data: transporter } = useTransporterProfile();

  return (
    <Wrapper>
      <AppHeader />

      <Container>{children}</Container>

      {transporter && (
        <Footer>
          <Stack space="medium" align="center">
            <Stack space="xxsmall" align="center">
              <TextBox variant="caption" color="secondary">
                {transporter.name} · {transporter.contact_phone} ·{" "}
                {transporter.contact_email}
              </TextBox>
              <TextBox variant="caption" color="secondary">
                USDOT {transporter.usdot_number} · {transporter.address}{" "}
                {transporter.city}, {transporter.state} {transporter.zip}
              </TextBox>
            </Stack>

            <Inline verticalAlign="center">
              <TextBox color="secondary">Powered by</TextBox>
              <LogoLink href="http://superdispatch.com/" target="_blank">
                <img src={PoweredBySD} alt="Super Dispatch" width="155" />
              </LogoLink>
            </Inline>
          </Stack>
        </Footer>
      )}
    </Wrapper>
  );
}
