import { yupPhone } from "shared/utils/YupUtils";
import { InferType, object, string } from "yup";

export type ManualQuoteRequestDTO = InferType<typeof manualQuoteRequestSchema>;
export const manualQuoteRequestSchema = object({
  company_name: string().required(),
  contact_name: string().required(),
  phone: yupPhone().required(),
  email: string().email().required(),
  vehicles_notes: string().nullable().max(200),
});

export type ManualQuoteResponseDTO = InferType<
  typeof manualQuoteResponseSchema
>;
export const manualQuoteResponseSchema = manualQuoteRequestSchema.shape({
  guid: string().defined(),
});
