import { Navigate, createBrowserRouter } from "react-router-dom";
import { OrderTransportPage } from "./pages/order-transport/OrderTransportPage";
import { PaymentSucceedPage } from "./pages/payment-success/PaymentSucceedPage";
import { ErrorPage } from "./ErrorPage";
import { QuoteRequestedPage } from "./pages/quote-requested/QuoteRequestedPage";
import { QuoteExpiredPage } from "./pages/quote-expired/QuoteExpiredPage";
import { QuotePaidPage } from "./pages/quote-paid/QuotePaidPage";

export const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <ErrorPage />,
    element: <OrderTransportPage />,
  },
  {
    path: "/payment-success",
    errorElement: <ErrorPage />,
    element: <PaymentSucceedPage />,
  },
  {
    path: "/quote-requested",
    errorElement: <ErrorPage />,
    element: <QuoteRequestedPage />,
  },
  {
    path: "/quote-expired",
    errorElement: <ErrorPage />,
    element: <QuoteExpiredPage />,
  },
  {
    path: "/order-booked",
    errorElement: <ErrorPage />,
    element: <QuotePaidPage />,
  },
  {
    path: "*",
    element: <Navigate to="/" />,
  },
]);
