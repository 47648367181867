import { FormikTextField, FormikTextFieldProps } from "@superdispatch/forms";

export function FormikTelField(props: FormikTextFieldProps) {
  return (
    <FormikTextField
      {...props}
      inputProps={{ inputMode: "tel" }}
      parse={(event) => event.target.value.replace(/[^0-9+]/g, "")}
    />
  );
}
