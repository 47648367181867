import { requestJSON } from "./OrderTransportAPI";

export function uploadAttachment(
  orderGuid: string,
  file: File
): Promise<object> {
  const body = new FormData();
  body.append("file", file, file.name);
  return requestJSON(
    `POST /api/order-transport/order/${orderGuid}/attachment`,
    {
      body,
    }
  );
}

export function deleteAttachement(orderGuid: string, attachmentGuid: string) {
  return requestJSON(
    `DELETE /api/order-transport/order/${orderGuid}/attachment/${attachmentGuid}`
  );
}
