import { joinStrings } from "../utils/StringUtils";

export function formatAddressLine(
  zip: null | string | undefined,
  city: null | string | undefined,
  state: null | string | undefined,
  country?: null | string
) {
  return joinStrings(", ", city, joinStrings(" ", state, zip), country);
}
