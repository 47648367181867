import {
  DependencyList,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

export function scheduleTimeout(fn: () => void, ms: number): () => void {
  const id = setTimeout(fn, ms);

  return () => {
    clearTimeout(id);
  };
}

export function useTimedOutEffect(
  effect: () => void,
  deps: DependencyList,
  ms: number
) {
  const effectRef = useRef(effect);

  useEffect(() => {
    effectRef.current = effect;
  });

  useEffect(() => {
    return scheduleTimeout(() => {
      effectRef.current();
    }, ms);
    // eslint-disable-next-line react-hooks/exhaustive-deps,@typescript-eslint/no-unsafe-assignment
  }, [ms, ...deps]);
}

export function useDebouncedValue<T>(value: T, delay: number): T {
  const [debounced, setDebounced] = useState<T>(value);

  useTimedOutEffect(
    () => {
      setDebounced(value);
    },
    [value],
    delay
  );

  return debounced;
}

export type SearchQueryTextType = [
  text: string,
  query: string | undefined,
  setText: Dispatch<SetStateAction<string>>
];

export function useSet<T>(
  initialState?: ReadonlySet<T> | (() => ReadonlySet<T>)
): [state: Set<T>, add: (key: T) => void, remove: (key: T) => void] {
  const [state, setState] = useState(() => {
    if (typeof initialState == "function") initialState = initialState();
    return new Set<T>(initialState);
  });

  const add = useCallback((key: T) => {
    setState((prev) => {
      if (prev.has(key)) return prev;
      const next = new Set(prev);
      next.add(key);
      return next;
    });
  }, []);

  const remove = useCallback((key: T) => {
    setState((prev) => {
      if (!prev.has(key)) return prev;
      const next = new Set(prev);
      next.delete(key);
      return next;
    });
  }, []);

  return [state, add, remove];
}
