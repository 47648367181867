import { InferType, object, string } from "yup";
import { normalizeURL } from "@superdispatch/uri";

export type TransporterDTO = InferType<typeof transporterSchema>;
export const transporterSchema = object({
  name: string().defined(),
  usdot_number: string().defined().nullable(),
  address: string().defined(),
  city: string().defined(),
  state: string().defined(),
  zip: string().defined(),
  contact_email: string().defined(),
  contact_phone: string().defined(),
  logo_url: string().defined().nullable(),
  personal_page_url: string()
    .defined()
    .nullable()
    .transform((value) => {
      return !value ? null : normalizeURL(value);
    }),
});
