import { yupDateString, yupPhone } from "shared/utils/YupUtils";
import { array, InferType, object, string } from "yup";
import { quoteSchema } from "./QuoteDTO";
import { quoteRequestSchema } from "./QuoteRequestDTO";
import { otfAttachmentSchema } from "./OTFAttachement";

export type OrderVenueDTO = InferType<typeof orderVenueSchema>;
export const orderVenueSchema = object({
  contact_name: string().required(),
  phone: yupPhone().required(),
  email: string().email().nullable(),
  notes: string().max(255).nullable(),
});

export type OrderPayloadDTO = InferType<typeof orderPayloadSchema>;
export const orderPayloadSchema = object({
  guid: string().nullable(),
  attachments: array(otfAttachmentSchema).default([]),
  quote_guid: string().defined(),
  company_name: string().required(),
  contact_name: string().required(),
  phone: yupPhone().required(),
  email: string().email().required(),
  delivery: orderVenueSchema,
  pickup: orderVenueSchema.shape({
    ready_date: yupDateString("DateISO").required(),
  }),
});

export type OrderDTO = InferType<typeof orderSchema>;
export const orderSchema = orderPayloadSchema.shape({
  quote: quoteSchema,
  quote_request: quoteRequestSchema,
});

export type OrderSubmitResponseDTO = { guid: string };
export type PaymentCheckoutResponseDTO = { checkout_session_url: string };
