import { DateFormat, stringifyDate } from "@superdispatch/dates";
import { PhoneService, PhoneValidationRules } from "@superdispatch/phones";
import { toFinite } from "lodash-es";
import { trim } from "./StringUtils";
import {
  addMethod,
  array,
  boolean,
  mixed,
  number,
  object,
  ObjectShape,
  Schema,
  setLocale,
  string,
  StringSchema,
} from "yup";

setLocale({
  mixed: {
    default: "Invalid value",
    required: "This field is required",
  },
  string: {
    length: "This field must have exactly ${length} characters",
    min: "This field must have at least ${min} characters",
    max: "Maximum length is ${max} characters",
    email: "Enter a valid email",
    url: "Value must be a valid URL",
  },
  number: {
    min: "Value must be greater than or equal to ${min}",
    max: "Value must be less than or equal to ${max}",
    lessThan: "Value must be less than ${less}",
    moreThan: "Value must be greater than ${more}",
    positive: "Value must be a positive number",
    negative: "Value must be a negative number",
    integer: "Value must be an integer",
  },
  date: {
    min: "Date must be later than ${min}",
    max: "Date must be at earlier than ${max}",
  },
});

/**
 * Reusable `object` schema with useful defaults:
 * 1. `defined` is required for the `InferType`.
 * 2. `noUnknown` to strip unknown properties.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function yupObject<T extends ObjectShape>(fields: T) {
  return object(fields).defined().noUnknown().nonNullable();
}

/**
 * Reusable `never` schema that allows to remove properties from
 * the `objectSchema`
 */
export const yupOmit: Schema<never | undefined> = mixed<never>()
  .optional()
  .transform(() => undefined);

/**
 * Reusable `array` schema with useful defaults:
 * 1. `defined` is required for the `InferType`.
 * 2. `ensure` casts `null` and `undefined` values to an empty array.
 */
export function yupArray<T>(schema: Schema<T>) {
  return array(schema).ensure();
}

/**
 * Reusable `string` schema with useful defaults:
 * 1. `defined` is required for the `InferType`.
 * 2. `ensure` casts `null` and `undefined` values to `string`.
 */
export function yupString(): StringSchema<string> {
  return string().defined().ensure();
}

/**
 * Reusable `number` schema with useful defaults:
 * 1. `defined` is required for the `InferType`.
 */
export function yupNumber() {
  return number().defined().nullable().default(null);
}

/**
 * Reusable `boolean` schema with useful defaults:
 * 1. `defined` is required for the `InferType`.
 * 2. `default` casts `undefined` values to `false`.
 * 3. `transform` casts `null` values to `boolean`.
 */
export function yupBoolean() {
  return boolean().defined().nullable().default(false);
}

/**
 * Reusable `enum` schema with useful defaults:
 * 1. `defaultValue` takes first value from the `allowedValues` when not defined.
 * 2. `defined` is required for the `InferType`.
 * 3. `default` casts `undefined` values to `false`.
 * 4. `transform` casts `null` values to `boolean`.
 */
export function yupEnum<T extends string | null>(
  allowedValues: readonly T[],
  defaultValue: T
) {
  if (allowedValues.length === 0) {
    throw new Error('[yupEnum] "allowedValues" array can not be empty.');
  }

  return mixed<T>()
    .defined()
    .default(defaultValue)
    .transform(function normalize(value: T) {
      return allowedValues.includes(value) ? value : this.getDefault();
    });
}

/**
 * Reusable `DateString` schema with useful defaults:
 * 1. `defined` is required for the `InferType`.
 * 2. `default` casts `undefined` values to `null`.
 * 3. `nullable` allows `null` inputs.
 * 4. Transforms all inputs to `NullableDateString`.
 */
export function yupDateString(format: DateFormat) {
  return string()
    .defined()
    .nullable()
    .default(null)
    .transform(
      (_, originalValue) => stringifyDate(originalValue, { format }) ?? null
    );
}

export type YupPhoneOptions = Omit<
  PhoneValidationRules,
  "required" | "requiredMessage"
>;

/**
 * Reusable `phone` schema with useful defaults:
 * 1. `defined` is required for the `InferType`.
 * 2. `ensure` casts `null` and `undefined` values to `string`.
 * 3. `trim` value.
 * 4. `test` phone number value.
 */
export function yupPhone(options?: YupPhoneOptions): StringSchema<string> {
  return yupString()
    .trim()
    .test(
      "phone-number",
      "Invalid Phone Number",
      function testPhoneNumber(value) {
        // Skip sync validation.
        if ("sync" in this.options) {
          return true;
        }

        return PhoneService.validate(value, options).then((message) => {
          if (!message) {
            return true;
          }

          return this.createError({ message });
        });
      }
    );
}

/**
 * Reusable `string` schema with useful defaults:
 * 1. `trim` search param.
 * 2. `nullable` allows `null` inputs.
 * 3. `default` will cast `undefined` to `null`.
 * 4. `transform` non `string` or empty strings to `null`.
 */
export function yupStringParam() {
  return string()
    .trim()
    .nullable()
    .default(null)
    .transform(function normalize(value) {
      return typeof value == "string" && value.length > 0
        ? value
        : this.getDefault();
    });
}

/**
 * Reusable `number` schema with useful defaults:
 * 1. `nullable` allows `null` inputs.
 * 2. `default` will cast `undefined` to `null`.
 * 3. `transform` non finite numbers to `default`.
 */
export function yupNumberParam() {
  return number()
    .nullable()
    .default(null)
    .transform(function normalize(value) {
      return typeof value == "number" && Number.isFinite(value)
        ? value
        : this.getDefault();
    });
}

/**
 * Reusable `boolean` schema with useful defaults:
 * 1. `nullable` allows `null` inputs.
 * 2. `default` will cast `undefined` to `null`.
 * 3. `transform` non `boolean` or non finite numbers to `default`.
 */
export function yupBooleanParam() {
  return boolean()
    .nullable()
    .default(null)
    .transform(function normalize(value) {
      return typeof value === "boolean" ? value : this.getDefault();
    });
}

export function transformQueryArray(value: unknown) {
  const values = Array.isArray(value)
    ? value
    : typeof value == "string"
    ? value.trim().split(",")
    : [value];

  const clean = Array.from(new Set(values.filter(Boolean)));
  return clean.join(",") || undefined;
}

export function transformStringToArray<T>(
  value?: T[],
  originalValue?: string | T[]
) {
  if (Array.isArray(value) && value.length) {
    return value;
  }

  if (Array.isArray(originalValue)) {
    return originalValue;
  }

  if (!originalValue) {
    return [];
  }

  return originalValue.trim().split(",");
}

export function ensureOptionalString(value: unknown): string | undefined {
  return value == null ? undefined : trim(value);
}

export function ensurePositiveNumber(value: unknown): number {
  return Math.max(0, toFinite(value));
}

interface MultipleEmailsValidatorProps {
  delimiter?: string;
  message?: string;
}
declare module "yup" {
  interface StringSchema {
    multipleEmails: (props?: MultipleEmailsValidatorProps) => StringSchema;
  }
}

addMethod<StringSchema>(
  string,
  "multipleEmails",
  // eslint-disable-next-line func-names
  function ({
    delimiter = ",",
    message = "Enter valid email(s)",
  }: MultipleEmailsValidatorProps = {}) {
    const emailsSchema = yupArray(yupString().email().required());

    return this.test("all-emails-valid", message, (value) => {
      if (!value || typeof value !== "string") {
        return true;
      }

      const emails = value
        .trim()
        .split(delimiter)
        .map((email) => email.trim());

      return emailsSchema.isValidSync(emails);
    });
  }
);

// Override Yup email validation to make it more strict
const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
addMethod(
  string,
  "email",
  function validateEmail(message = "Enter a valid email") {
    return this.matches(EMAIL_REGEX, {
      message,
      name: "email",
      excludeEmptyString: true,
    });
  }
);
