import { yupEnum, yupObject, yupString } from "shared/utils/YupUtils";
import { InferType, mixed } from "yup";

export type OTFAttachmentDTO = InferType<typeof otfAttachmentSchema>;

export const otfAttachmentSchema = yupObject({
  guid: yupString(),
  name: yupString(),
  file: mixed().nullable(),
  content_type: yupString(),
  url: yupString(),
});
