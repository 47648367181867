import { isAPIError } from "./data/OrderTransportAPI";
import { set } from "lodash-es";

export function getFormErrors(error: unknown): Record<string, string> {
  const errors: Record<string, string> = {};

  if (!isAPIError(error)) {
    return errors;
  }

  for (const item of error.details) {
    if (item.loc) {
      set(errors, item.loc.slice(1), item.message);
    }
  }

  return errors;
}
