import { Card, CardContent, Typography } from "@material-ui/core";
import { AttachFile } from "@material-ui/icons";
import { Inline, Stack } from "@superdispatch/ui";
import { FileListItem } from "@superdispatch/ui-lab";
import { getIn, setIn, useFormikContext } from "formik";
import { Ref, useCallback, useMemo } from "react";
import { FileDropZone } from "./FileDropZone";
import { OTFAttachmentDTO } from "../../data/OTFAttachement";
import { deleteAttachement } from "../../data/OrderAttaachmentAPI";

function getFileHash(file: File): string {
  return `${file.name}-${file.type}-${file.size}-${file.lastModified}`;
}

function getAttachments(values: unknown): OTFAttachmentDTO[] {
  const value = getIn(values, "attachments") as OTFAttachmentDTO[];
  return Array.isArray(value) ? value : [];
}

export interface OrderAttachmentsCardProps {
  nodeRef?: Ref<HTMLElement>;
  orderGuid?: string | null;
}

export function OrderAttachmentsCard({
  nodeRef,
  orderGuid,
}: OrderAttachmentsCardProps) {
  const { values, setValues, isSubmitting } = useFormikContext();
  const attachments = useMemo(() => getAttachments(values), [values]);

  const setAttachments = useCallback(
    (fn: (prevAttachments: OTFAttachmentDTO[]) => OTFAttachmentDTO[]) => {
      setValues((prevValues: unknown) => {
        const prevAttachments = getAttachments(prevValues);
        return setIn(prevValues, "attachments", fn(prevAttachments)) as unknown;
      });
    },
    [setValues]
  );

  return (
    <Card ref={nodeRef}>
      <CardContent>
        <Stack space="small">
          <Inline verticalAlign="center" space="xsmall">
            <AttachFile />
            <Typography variant="h3">Attachments</Typography>
          </Inline>

          <Stack space="small">
            <FileDropZone
              disabled={isSubmitting}
              onDropAccepted={(acceptedFiles) => {
                setAttachments((prevAttachments) => [
                  ...prevAttachments,
                  ...acceptedFiles.map((file) => {
                    const guid = getFileHash(file);
                    return {
                      guid,
                      file: file,
                      url: "",
                      name: file.name,
                      content_type: file.type,
                    };
                  }),
                ]);
              }}
            />

            {!!attachments.length && (
              <Stack>
                {attachments.map((attachment) => (
                  <FileListItem
                    name={attachment.name}
                    key={attachment.guid}
                    onDelete={() => {
                      if (orderGuid && attachment.url) {
                        deleteAttachement(orderGuid, attachment.guid);
                      }
                      setAttachments((prevAttachments) =>
                        prevAttachments.filter(
                          (prevAttachment) =>
                            prevAttachment.guid !== attachment.guid
                        )
                      );
                    }}
                  />
                ))}
              </Stack>
            )}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
