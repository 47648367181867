import {
  Chip,
  CircularProgress,
  InputAdornment,
  StandardTextFieldProps,
  TextField,
} from "@material-ui/core";
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteProps,
} from "@material-ui/lab";
import { forwardRef, ReactNode, useMemo, useState } from "react";
import { Geocoding, isGeocoding } from "../services/geo/GeoHelpers";
import { formatAddressLine } from "../helpers/AddressHelpers";
import { useDebouncedValue } from "../helpers/ReactHelpers";
import styled from "styled-components";
import { GeocodeType, useGeoPredictions } from "../services/geo/GeoService";

interface GeoFieldCommonProps
  extends Omit<
    AutocompleteProps<Geocoding, true, true, false>,
    "renderInput" | "defaultValue" | "value"
  > {
  TextFieldProps?: StandardTextFieldProps;
}

export interface UseGeoFieldPropsOptions {
  provider?: "mapbox" | "openstreeetmap" | "hybrid";
  types?: GeocodeType[];
  countries?: string[];
  formatOptionLabel?: (option: Geocoding) => string;
}

export function useGeoFieldProps({
  provider,
  types,
  countries,
  formatOptionLabel = (option) =>
    formatAddressLine(
      option.postcode,
      option.place,
      option.region_short,
      option.country
    ),
}: UseGeoFieldPropsOptions): GeoFieldCommonProps {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState<string | undefined>();
  const debouncedQuery = useDebouncedValue(query, 200);
  const predictions = useGeoPredictions(
    provider,
    debouncedQuery,
    types,
    countries
  );

  const isLoading = isOpen && predictions.isLoading;
  const options = useMemo(() => {
    return predictions.data || [];
  }, [predictions]);

  return {
    options,
    forcePopupIcon: false,
    onOpen: () => {
      setIsOpen(true);
    },
    onClose: () => {
      setIsOpen(false);
    },
    filterOptions: (options) => options,
    includeInputInList: true,
    getOptionLabel: (option: Geocoding | string) =>
      typeof option === "string" ? option : formatOptionLabel(option),
    disableClearable: isLoading as true,
    loading: isLoading,
    onInputChange: (_, inputValue, reason) => {
      // prevent extra mapbox request
      if (reason === "reset") return;

      setQuery(inputValue);
    },
  };
}

const StartIcon = styled.div`
  display: flex;
  align-items: center;
  position: absolute;

  top: 0;
  bottom: 0;
  left: 8px;
`;

const MultipleGeoFieldText = styled(TextField)`
  /* Inline input next to tags rather than next line on focus */
  & .MuiInputBase-input:not(:focus) {
    min-width: auto;
  }

  &[data-start-icon="true"] .MuiOutlinedInput-adornedStart {
    padding-left: 40px;
  }
`;

export interface MapboxGeoFieldMultipleProps
  extends Omit<GeoFieldCommonProps, "options" | "onChange" | "getOptionLabel">,
    UseGeoFieldPropsOptions {
  value: Geocoding[];
  startIcon?: ReactNode;
  defaultValue?: Geocoding[];
  onChange?: (
    value: Geocoding[] | undefined,
    reason: AutocompleteChangeReason
  ) => void;
}

export const MapboxGeoFieldMultiple = forwardRef(
  (
    {
      types,
      onChange,
      startIcon,
      TextFieldProps,
      formatOptionLabel,
      disableClearable = true,
      ...props
    }: MapboxGeoFieldMultipleProps,
    ref
  ) => {
    const autocompleteProps = useGeoFieldProps({ types, formatOptionLabel });

    return (
      <Autocomplete
        {...props}
        {...autocompleteProps}
        ref={ref}
        multiple={true}
        onChange={(_, selectedValue, reason) =>
          onChange?.(
            !Array.isArray(selectedValue)
              ? undefined
              : selectedValue.filter(isGeocoding),
            reason
          )
        }
        renderTags={(items: Geocoding[], getTagProps) =>
          items.map((option, index) => (
            <Chip
              key={index}
              label={formatAddressLine(
                option.postcode,
                option.place,
                option.region_short
              )}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <MultipleGeoFieldText
            {...params}
            {...TextFieldProps}
            data-start-icon={String(!!startIcon)}
            InputProps={{
              ...TextFieldProps?.InputProps,
              ...params.InputProps,
              startAdornment: (
                <>
                  {startIcon && <StartIcon>{startIcon}</StartIcon>}
                  {params.InputProps.startAdornment}
                </>
              ),
              endAdornment: autocompleteProps.loading ? (
                <InputAdornment position="end">
                  <CircularProgress color="inherit" size={20} />
                </InputAdornment>
              ) : (
                params.InputProps.endAdornment
              ),
            }}
            variant="outlined"
            fullWidth={true}
          />
        )}
      />
    );
  }
);

MapboxGeoFieldMultiple.displayName = "MapboxGeoFieldMultiple";
