import React from "react";
import ReactDOM from "react-dom/client";

import { initMonitoring } from "shared/services/MonitoringService";
import "shared/utils/YupUtils";
import { ThemeProvider } from "@superdispatch/ui";
import { QueryClient, QueryClientProvider } from "react-query";

import { App } from "./App";

if (import.meta.env.VITE_APP_TARGET !== "local") {
  initMonitoring("order-transport-web", "fbe722e2-9d7e-4f56-9ff0-761dd5d32601");
}

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: false } },
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
