import { useQuery } from "react-query";
import { Geocoding } from "./GeoHelpers";
import { GeocodeType } from "./GeoService";
import { uniqBy } from "lodash-es";
import { forwardGeocode } from "./Mapbox";
import { parseGeocodeFeature } from "./MapboxHelpers";
import { searchOSM } from "./OpenStreetMap";
import { logInfo } from "../MonitoringService";

export function useHybridPrediction(
  query: string | undefined,
  types: Array<GeocodeType> = [],
  countries: string[] = ["US"]
) {
  return useQuery<Geocoding[], Error>(
    ["hybrid", "search", { query, types, countries }],
    () => {
      if (!query) {
        throw new Error("Empty search input");
      }

      return searchOSM(query, types).then((response) => {
        if (response.length === 0 && query.length > 20) {
          logInfo("Empty OSM results", { query, types });
          return forwardGeocode({
            types,
            query,
            countries,
            autocomplete: true,
          });
        }

        return response;
      });
    },
    {
      enabled: !!query,
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );
}

