import { OpenStreetMapProvider } from "leaflet-geosearch";
import { RawResult } from "leaflet-geosearch/dist/providers/openStreetMapProvider";
import {
  SearchArgument,
  SearchResult,
} from "leaflet-geosearch/dist/providers/provider";
import { useQuery } from "react-query";
import { logError, logWarning } from "../MonitoringService";
import { Geocoding } from "./GeoHelpers";
import { joinStrings } from "../../utils/StringUtils";
import { GeocodeType } from "./GeoService";
import { omitBy, uniqBy } from "lodash-es";

const provider = new OpenStreetMapProvider({
  params: { countrycodes: "us", addressdetails: 1 },
});

export interface OpenStreetMapAddress {
  "ISO3166-2-lvl4": string;
  country: string;
  country_code: string;
  state?: string;
  county?: string;
  postcode?: string;
  village?: string;
  city: string;
  town?: string;
  road?: string;
  neighbourhood?: string;
  suburb?: string;
  house_number?: string;
  building?: string;
  amenity?: string;
  hamlet?: string;
  office?: string;
  man_made?: string;
}

export type OpenStreetMapSearchParams = string | Partial<OpenStreetMapAddress>;

interface OpenStreetMapResponse extends RawResult {
  address?: OpenStreetMapAddress;
}

export function searchOSM(query: OpenStreetMapSearchParams, types: GeocodeType[]) {
  return provider
    .search({ query } as SearchArgument) // OpenStreetMap typing is incorrect
    .then((data) => data.map(parseOpensStreetMapFeature))
    .then((response) => {
      const uniqueResults = uniqBy(response, (item) => {
        // exclude lat/long as they may differ with smal percision
        const { latitude, longitude, ...rest } = item;
        return JSON.stringify(rest);
      });

      return uniqueResults.filter((item) => {
        return types.every((x) => !!item[x]);
      })
    })
    .catch((error) => {
      logWarning("Failed to search OSM", {
        error,
        query,
      });
      throw error;
    });
}

export function useOpenStreetMap(
  query: OpenStreetMapSearchParams | undefined,
  types: Array<GeocodeType> = []
) {
  return useQuery<Geocoding[], Error>(
    ["openstreetmap", "search", { query }],
    () => {
      if (!query) {
        throw new Error("Empty search input");
      }

      return searchOSM(query, types);
    },
    {
      enabled: !!query,
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );
}

export function parseOpensStreetMapFeature(
  result: SearchResult<OpenStreetMapResponse>
): Geocoding {
  const address = result.raw.address;

  return {
    house_number: address?.house_number,
    street: address?.road,
    postcode: address?.postcode,
    region: address?.state,
    country: address?.country,
    country_short: address?.country_code,
    neighborhood: address?.neighbourhood,
    locality: address?.suburb,
    district: address?.county,
    place: address?.city || address?.town,

    longitude: parseFloat(result.raw.lon),
    latitude: parseFloat(result.raw.lat),

    region_short: address?.["ISO3166-2-lvl4"]?.replace("US-", ""),
    address: joinStrings(" ", address?.house_number, address?.road),
  };
}
