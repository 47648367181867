import { useHybridPrediction } from "./HybridGeoService";
import { useMapboxPredictions } from "./Mapbox";
import { useOpenStreetMap } from "./OpenStreetMap";

export type GeocodeType =
  | "country"
  | "region"
  | "postcode"
  | "district"
  | "place"
  | "locality"
  | "neighborhood"
  | "address";

export function useGeoPredictions(
  provider: "mapbox" | "openstreeetmap" | "hybrid" = "mapbox",
  query: string | undefined,
  types?: GeocodeType[],
  countries: string[] = ["US"]
) {
  const osm = useOpenStreetMap(
    provider === "openstreeetmap" ? query : undefined,
    types
  );
  const mapbox = useMapboxPredictions(
    provider === "mapbox" ? query : undefined,
    types,
    countries
  );
  const hybrid = useHybridPrediction(
    provider === "hybrid" ? query : undefined,
    types,
    countries
  );

  return provider === "openstreeetmap" ? osm : provider === "mapbox" ? mapbox : hybrid;
}
