import { useField, useFormikContext } from "formik";
import { GeoField, GeoFieldSingleProps } from "shared/ui/GeoField";
import { Geocoding } from "shared/services/geo/GeoHelpers";
import { joinStrings } from "shared/utils/StringUtils";
import { dequal } from "dequal/lite";
import { QuoteRequestVenueDTO } from "../../data/QuoteRequestDTO";

function mapGeocodeToVenue({
  postcode,
  place,
  region_short,
  longitude,
  latitude,
  address,
}: Geocoding) {
  return {
    latitude,
    longitude,
    address,
    zip: postcode,
    city: place,
    state: region_short,
  };
}

function mapVenueToGeocode({
  zip,
  city,
  state,
  longitude,
  latitude,
  address,
}: QuoteRequestVenueDTO): Geocoding {
  return {
    latitude,
    longitude,
    address,
    postcode: zip || undefined,
    place: city || undefined,
    region_short: state,
  };
}

function formatOptionLabel({
  postcode,
  place,
  region_short,
  country,
  address,
}: Geocoding) {
  return joinStrings(
    ", ",
    address,
    place,
    joinStrings(" ", region_short, postcode),
    country === "United States" ? "USA" : country
  );
}

interface VenueFieldProps extends Omit<GeoFieldSingleProps, "value" | "types"> {
  name: string;
}

export function VenueField({ name, TextFieldProps, ...rest }: VenueFieldProps) {
  const { isSubmitting } = useFormikContext();
  const [{ value }, { error, touched }, { setValue }] = useField<any | null>(
    name
  );

  const venueError = error as
    | Record<keyof QuoteRequestVenueDTO, string>
    | string
    | undefined;

  const errorText = touched && venueError;

  return (
    <GeoField
      {...rest}
      fullWidth={true}
      disabled={isSubmitting}
      provider="hybrid"
      types={["address", "postcode", "place", "region"]}
      value={!value ? null : mapVenueToGeocode(value)}
      noOptionsText="Enter Full Address"
      getOptionSelected={(option, val) => {
        // convert both items into same format
        return dequal(mapGeocodeToVenue(option), mapGeocodeToVenue(val));
      }}
      formatOptionLabel={formatOptionLabel}
      onChange={(updatedValue) => {
        setValue(!updatedValue ? null : mapGeocodeToVenue(updatedValue));
      }}
      TextFieldProps={{
        ...TextFieldProps,
        name,
        fullWidth: true,
        error: !!errorText,
        helperText:
          !!errorText &&
          (typeof errorText === "string"
            ? errorText
            : errorText.longitude ||
              errorText.latitude ||
              errorText.zip ||
              errorText.city ||
              errorText.state ||
              errorText.address),
      }}
    />
  );
}
