import createGeocodingClient, {
  GeocodeQueryType,
  GeocodeRequest,
  GeocodeResponse,
} from "@mapbox/mapbox-sdk/services/geocoding";
import { get } from "lodash-es";
import { useQuery, UseQueryResult } from "react-query";
import { logWarning } from "../MonitoringService";
import { parseGeocodeFeature } from "./MapboxHelpers";
import { Geocoding } from "./GeoHelpers";
import { trim } from "../../utils/StringUtils";

// Order Transport: pk.eyJ1Ijoic3VwZXJkaXNwYXRjaCIsImEiOiJjbGo0MjBpazYxanB6M25wOWN1NnI2Z2x4In0.VVJ5YfU2vOH9X8kj_ZDdJA
const ACCESS_TOKEN =
// Carrier Pay Calculator Web
  "pk.eyJ1Ijoic3VwZXJkaXNwYXRjaCIsImEiOiJjbGY5YnVpaGUyZXNjM3ZyMGF2ZWlheXlkIn0.GNel0j3DRt3ZlB8Yv9wh_Q";

const geocodingService = createGeocodingClient({
  accessToken: ACCESS_TOKEN,
});

export function useMapboxPredictions(
  query: string | undefined,
  types: GeocodeQueryType[] = [],
  countries: string[] = ["US"]
): UseQueryResult<Geocoding[], Error> {
  return useQuery(
    [
      "mapbox",
      "predictions",
      {
        query: trim(query), // trim in order to prevent multipe calls for extra whitespaces
        types,
        countries,
      },
    ],
    () =>
      forwardGeocode({
        types,
        countries,
        autocomplete: true,
        query: query as string,
      }),
    {
      enabled: !!query,
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
}

export function forwardGeocode(
  request: GeocodeRequest
): Promise<Geocoding[]> {
  return geocodingService
    .forwardGeocode({
      mode: "mapbox.places",
      ...request,
    })
    .send()
    .catch((error) => {
      const mapboxError = createMapboxError(error);

      logWarning("Failed to search Mapbox", {
        mapboxError,
        request,
        original_error: error,
      });

      throw createMapboxError(mapboxError);
    })
    .then((res) => {
      return res.body.features.map(parseGeocodeFeature);
    });
}

function createMapboxError(error: unknown) {
  if (error instanceof Error) {
    return error;
  }

  const errorMessage = get(error, "message");
  if (typeof errorMessage === "string") {
    return new Error(errorMessage);
  }

  return new Error("Failed to execute Geo service queries.");
}
