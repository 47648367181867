import {
  FileDropZone as UIFileDropZone,
  FileDropZoneProps,
  toBytes,
} from "@superdispatch/ui-lab";
import { RefAttributes } from "react";
import { FileRejection } from "react-dropzone";
import { logWarning } from "shared/services/MonitoringService";

export const MAX_FILE_SIZE = toBytes(10, "mb");
export const ACCEPTED_FILE_TYPES =
  ".jpeg, .jpg, .png, .heic, .heif, .webp, .pdf, .csv, text/plain, .doc, .docx, .xls, .xlsx";
export function FileDropZone({
  children,
  accept = ACCEPTED_FILE_TYPES,
  maxSize = MAX_FILE_SIZE,
  onDropRejected,
  ...rest
}: FileDropZoneProps & RefAttributes<HTMLButtonElement>) {
  return (
    <UIFileDropZone
      maxSize={maxSize}
      accept={accept}
      hintText="or Drag & Drop files less than 10 MB"
      onDropRejected={(fileRejections) => {
        captureFileTypeError(fileRejections);
        onDropRejected?.(fileRejections);
      }}
      {...rest}
    >
      {children}
    </UIFileDropZone>
  );
}

function captureFileTypeError(fileRejectionList: FileRejection[]) {
  //filter only 'file-invalid-type' errors and extract type of files.
  const types = fileRejectionList
    .filter((fileRejection) =>
      fileRejection.errors.some((error) => error.code === "file-invalid-type")
    )
    .map((fileRejection) => fileRejection.file.type);

  if (types.length > 0) {
    logWarning("Unsupported Media Type in FileDropZone", { fileType: types });
  }
}
