import { array, boolean, InferType, number, object, string } from "yup";
import { validate } from "@voxasoftworks/vin";
import { dequal } from "dequal/lite";

export type QuoteRequestVehicleDTO = InferType<
  typeof quoteRequestVehicleSchema
>;
export const quoteRequestVehicleSchema = object({
  year: number().nullable(),
  make: string().nullable(),
  model: string().nullable(),
  is_inoperable: boolean().default(false),
  vin: string()
    .ensure()
    .required("VIN is required")
    .test("validateVin", "Invalid VIN", validate),
});

export type QuoteRequestVenueDTO = InferType<typeof quoteRequestVenueSchema>;
export const quoteRequestVenueSchema = object({
  latitude: number().defined(),
  longitude: number().defined(),
  zip: string().required("Please include zip"),
  city: string().required("Please include city"),
  state: string().required("Please include state"),
  address: string().required("Please include street"),
}).noUnknown();

export type QuoteRequestDTO = InferType<typeof quoteRequestSchema>;
export const quoteRequestSchema = object({
  pickup: quoteRequestVenueSchema.required("Enter Pickup address"),
  delivery: quoteRequestVenueSchema
    .required("Enter Delivery address")
    .test(
      "same-venues",
      "Please use different address than Pickup",
      (value, context) => !dequal(value, context.parent.pickup)
    ),
  trailer_type: string().nullable(),
  vehicles: array(quoteRequestVehicleSchema).ensure().defined(),
});
