import React from "react";
import { TextBox } from "@superdispatch/ui-lab";

export function PaymentView() {
  return (
    <TextBox variant="heading-3" color="secondary">
      Payment
    </TextBox>
  );
}
