import styled from "styled-components";

const NestedItem = styled.li`
  & ol {
    counter-reset: item;
  }

  & li {
    display: block;
  }

  & li:before {
    content: attr(data-count) "." counter(item) " ";
    counter-increment: item;
  }
`;

export function TermsConditionsContent() {
  return (
    <p>
      Thank you for your interest in BacklotCars, Inc.’s d/b/a OPENLANE
      (“OPENLANE”) transportation brokerage services. OPENLANE is a licensed
      vehicle transportation broker. By accepting these Non-Sales Related Terms
      and Conditions (“Terms and Conditions”), or otherwise using OPELANE’s
      transportation brokerage services, the individual requesting
      transportation broker services (“Customer”) agrees to be bound by these
      Terms and Conditions, which may be amended from time to time in OPENLANE’s
      sole discretion, and which shall govern the relationship and become a part
      of any and all transactions or dealings by and between Customer and
      OPENLANE for vehicle transportation brokerage services.
      <br />
      <ol>
        <li>
          <b>Services.</b> OPENLANE is a vehicle transportation broker only and
          provides such services pursuant to USDOT Number 3152201 and Docket
          Number MC-105562. OPENLANE does not own or operate any trucks, operate
          as or assume liability of a motor carrier, or take physical possession
          of vehicles, and is not otherwise a party to the vehicle sale
          contract. Rather, OPENLANE uses an extensive network of third-party
          independent motor carriers to transport vehicles. These motor carriers
          are independently insured and have “Active” status with the U.S.
          Department of Transportation Federal Motor Carrier Safety
          Administration. OPENLANE does not warrant or otherwise endorse the
          services provided by any third-party motor carriers. Customer
          authorizes OPENLANE to arrange transportation of a vehicle with a
          third-party motor carrier. The fee for the transport, which will
          include OPENLANE’s transportation brokerage services, will be quoted
          at the time of a transport request.
        </li>
        <li>
          <b>Payment:</b> Customer shall pay OPENLANE all quoted fees upon
          acceptance of OPENLANE’s transportation brokerage services.
        </li>
        <li>
          <b>Customer’s Representations and Obligations.</b> Customer makes the
          following representations and agrees to the following obligations:
          <ol type="a">
            <li>
              Customer is either the registered owner of the vehicle requested
              to be transported or has the legal authority to request transport
              on behalf of the legal owner of the vehicle;
            </li>
            <li>
              Customer will prepare the vehicle for safe transport, including,
              but not limited to, removing or securing any loose parts and
              disclosing any safety concerns to the transporter;
            </li>
            <li>
              Customer agrees to deactivate any alarm system prior to transport;
            </li>
            <li>
              Customer agrees to remove any personal items from the vehicle
              prior to transport and failure to do so may result in additional
              fees;
            </li>
            <li>
              Customer or its authorized representative shall be present at the
              time of pickup and drop off to sign for the removal and acceptance
              of the vehicle;
            </li>
            <li>
              Customer shall maintain insurance on the vehicle until the vehicle
              is delivered;
            </li>
            <li>
              Customer consents to the motor carrier operating the vehicle in a
              manner necessary to perform transport services;
            </li>
            <li>
              Customer represents the vehicle is operable. If the vehicle is
              inoperable, additional fees may apply;
            </li>
            <li>
              Customer shall identify and disclose in writing any damage to the
              vehicle it believes was caused during the transport on the bill of
              lading upon delivery. Any damage not listed on the bill of lading
              at the time of delivery is waived; and
            </li>
            <li>
              Customer acknowledges that delivery times provided are estimates
              only. OPENLANE does not guarantee that the vehicle will be
              delivered within the timeframe indicated. OPENLANE will not be
              liable for any costs, expenses, damages, penalties, fines, or
              losses associated with the late delivery of a vehicle.
            </li>
          </ol>
        </li>
        <li>
          <b>Claims Waiver.</b> Any claim related to the transport shall be
          between the motor carrier and Customer. OPENLANE shall have no
          liability for damages or losses arising out of such transportation as
          a broker or otherwise. Customer agrees its sole remedy is against the
          motor carrier or through its own insurance coverage, and Customer
          agrees to file a waiver of subrogation with its insurers in favor of
          OPENLANE. Customer agrees that OPENLANE is not liable for any theft,
          conversion, loss, injury, death, damage, claim, expense, lawsuit, or
          demand in any way arising out of or relating to transported vehicles.{" "}
        </li>
        <li>
          <b>Force Majeure.</b> In the event of a “force majeure” events beyond
          the reasonable control of the party seeking the enforce the “force
          majeure” clause, performance of this Agreement is suspended during the
          “force majeure” event.
        </li>
        <li>
          <b>Indemnification.</b> Customer shall defend, indemnify, and hold
          harmless OPENLANE from and against any claims arising out of or
          relating to Customer’s breach of these Terms and Conditions or
          Customer’s negligence or willful misconduct. Customer’s indemnity
          obligations shall include OPENLANE’s attorneys’ fees and court costs.
        </li>
        <NestedItem>
          <b>
            MANDATORY AND BINDING DISPUTE RESOLUTION AND ARBITRATION PROCEDURES-
            SUBMISSION TO ARBITRATION.
          </b>{" "}
          PLEASE READ THIS SECTION CAREFULLY – IT MAY SIGNIFICANTLY AFFECT
          CUSTOMER’S LEGAL RIGHTS, INCLUDING CUSTOMER’S RIGHT TO FILE A LAWSUIT
          IN COURT. CUSTOMER UNDERSTANDS AND AGREES THAT BY THIS PROVISION,
          CUSTOMER IS FORGOING THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL.
          CUSTOMER AGREES THAT ANY AND ALL DISPUTES THAT HAVE ARISEN OR MAY
          ARISE BETWEEN OPENLANE AND CUSTOMER THAT CANNOT BE RESOLVED THROUGH
          NEGOIATION AS SET FORTH IN THIS SECTION 7 SHALL BE RESOLVED
          EXCLUSIVELY THROUGH FINAL AND BINDING ARBITRATION, RATHER THAN IN
          COURT (EXCEPT AS OTHERWISE PROVIDED IN THIS SECTION 7).
          <ol>
            <li data-count="7">
              Customer (“You”) on one hand, and OPENLANE, on the other hand,
              agree that any and all claims or disputes between them
              (“Disputes”) shall be submitted to and resolved exclusively by
              binding arbitration before a single arbitrator instead of
              litigation (except as otherwise provided in this Section 7).
              Arbitration is more informal than a lawsuit in court. Arbitration
              uses a neutral arbitrator instead of a judge or jury, allows for
              more limited discovery than in court and is subject to very
              limited review by courts. Arbitrators can award the same damages
              and relief that a court can award (subject to the limitations in
              this Section 7). This agreement to arbitrate is intended to be
              broadly interpreted. It includes, but is not limited to: (1)
              Disputes arising out of or related in any way to these Terms and
              Conditions; (2) Disputes arising out of or related in any way to
              transactions, payments, or fees associated with these Terms and
              Conditions; (3) Disputes arising out of or related in any way to
              the Terms and Conditions or any of OPENLANE’s policies or
              procedures; (4) Disputes that arose before Customer’s agreement to
              the Terms and Conditions or any prior agreement with OPENLANE; (5)
              Disputes that are currently the subject of purported class action
              litigation in which Customer is not a member of a certified class;
              (6) Disputes that may arise after the termination of Customer’s
              use of the Services; and (7) Disputes arising out of or related to
              any dealings with OPENLANE.
            </li>

            <li data-count="7">
              Notwithstanding the foregoing, if You have a Dispute with
              OPENLANE, you must first send written notice describing the
              Dispute to OPENLANE to allow OPENLANE an opportunity to resolve
              the Dispute informally through negotiation. You must send your
              notice to the following email address: transport@openlane.com. If
              OPENLANE has a Dispute with You, OPENLANE will send written notice
              describing the Dispute to You. You and OPENLANE agree to negotiate
              resolution of a Dispute in good faith for no fewer than 30 days
              after notice of a Dispute has been received subject to reasonable
              requests for extensions on both sides. If the Dispute is not
              resolved within 30 days from receipt of notice of the Dispute, You
              or OPENLANE may proceed to have the Dispute resolved through
              arbitration as each party's exclusive Dispute resolution process
              (except for the limited exceptions set forth in this Section 7).
              The arbitration filing must attach the OPENLANE service invoice
              and bill of lading for each vehicle subject to the arbitration
              claim.
            </li>

            <p>
              MANDATORY AND BINDING DISPUTE RESOLUTION AND ARBITRATION
              PROCEDURES
            </p>

            <li data-count="7">
              The Federal Arbitration Act and federal arbitration law apply to
              arbitration under the Terms and Conditions. The arbitration shall
              be held in Jackson County, Missouri. Customer and OPENLANE may
              elect to have the arbitration conducted based solely on written
              submissions, subject to the arbitrator’s discretion to require an
              in-person hearing. In cases where an in-person hearing is held,
              Customer or OPENLANE may attend by telephone or video feed, unless
              the arbitrator requires otherwise.
            </li>

            <li data-count="7">
              The arbitrator will decide all threshold questions, including but
              not limited to enforceability, revocability, and validity of this
              Section 7, whether any party lacks standing to assert the
              claims(s), and the substance of the Dispute in accordance with the
              laws of the state of Missouri, regardless of choice of law
              principles, and will honor all claims of privilege recognized by
              law. The arbitrator will have the power to award a party any
              relief or remedy that the party could have received in court in
              accordance with the law(s) that apply to the Dispute, subject to
              the limitations of this Section 7. Any result reached by the
              Arbitrator shall be final and binding on all parties to the
              arbitration, and no appeal may be taken. Any party to any award
              rendered in such arbitration proceeding may seek a judgment upon
              the award and that judgment may be entered thereupon by any court
              having jurisdiction.
            </li>
            <li data-count="7">
              Payment of all filing, administration and arbitrator fees will be
              governed by the American Arbitration Association’s (“AAA’s”) rules
              (available at www.adr.org), unless otherwise stated in these Terms
              and Conditions with the filing party solely responsible for any
              filing fees or arbitration commencement. Notwithstanding the
              foregoing, OPENLANE will pay as much of the filing, administration
              and arbitrator fees as the arbitrator ultimately deems necessary
              to ensure fairness and enforceability of the arbitration and to
              prevent the arbitration from being cost-prohibitive in comparison
              to court litigation, unless the arbitrator determines that the
              Dispute was filed for purposes of harassment or is patently
              frivolous.
            </li>
            <li data-count="7">
              CLASS, MASS, REPRESENTATIVE, AND CONSOLIDATED ACTION WAIVER: ANY
              PROCEEDINGS WILL BE CONDUCTED ONLY ON AN INDIVIDUAL BASIS AND NOT
              IN A CLASS, MASS, CONSOLIDATED, OR REPRESENTATIVE ACTION. NEITHER
              PARTY SHALL BE A MEMBER IN A CLASS, MASS, CONSOLIDATED, OR
              REPRESENTATIVE ACTION OR PROCEEDING, AND THE ARBITRATOR MAY AWARD
              RELIEF ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND
              ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF WARRANTED BY THAT
              PARTY’S INDIVIDUAL DISPUTE OR CLAIM. THE ARBITRATOR MAY NOT
              CONSOLIDATE MORE THAN ONE DISPUTE, AND MAY NOT OTHERWISE PRESIDE
              OVER ANY FORM OF A MASS, CONSOLIDATD, REPRESENTATIVE OR CLASS
              PROCEEDING. THE PARTIES HEREBY WAIVE ANY FORM OF CLASS, MASS,
              REPRESENTATIVE, OR CONSOLIDATED PROCEEDING OR RELIEF IN ANY VENUE.
              THE PARTIES ALSO HEREBY WAIVE ANY RIGHT TO A JURY TRIAL.
            </li>
            <li data-count="7">
              ARBITRATION OPT OUT: Customer has the right to opt out and not be
              bound by this arbitration provision by sending written notice of
              its decision to opt out to: transport@openlane.com. This notice
              must be sent within five (5) days of when you first register as a
              Customer or, if you are already a Customer, upon initial release
              of this arbitration provision, within five (5) days of your
              acceptance of these Terms and Conditions.
            </li>
            <li data-count="7">
              The opt-out notice must state that Customer does not agree to this
              agreement to arbitrate and must include your name, address, phone
              number and e-mail address(es) used to register as a Customer.
            </li>

            <p>
              Customer must sign the opt-out notice for it to be effective. Any
              opt-out not received within the applicable five (5) day period set
              forth above will not be valid.
            </p>

            <li data-count="7">
              If Customer opts out of the agreement to arbitrate, Customer and
              OPENLANE agree that any Disputes will be resolved by a state or
              federal court located in Jackson County, Missouri, and Customer
              consents to the jurisdiction and venue of such court as well as
              application of Missouri law, regardless of choice-of-law
              principles.
            </li>
            <li data-count="7">
              SMALL CLAIMS. Either Customer or OPENLANE may elect to pursue or
              defend a Dispute in, or if an arbitration demand has been made, to
              transfer a Dispute to, small claims court rather than in
              arbitration if the matter meets the local or state rules related
              to the monetary limits on small claims cases. If an arbitration
              demand has been made, the arbitrator shall dismiss the arbitration
              upon the election of either party to have the Dispute heard in
              small claims court.
            </li>
            <li data-count="7">
              INJUNCTIVE RELIEF: Notwithstanding anything to the contrary in the
              foregoing, either party may bring suit in court seeking temporary
              or preliminary injunctive relief only, which shall then be subject
              to review by the arbitrator should such party further seek
              permanent injunctive relief in arbitration.
            </li>
          </ol>
        </NestedItem>
        <li>
          <b>Modifications to Terms:</b> OPENLANE, at its sole discretion, may
          amend these Terms and Conditions upon notice to Customers. The amended
          Terms and Conditions will be effective immediately upon posting to the
          OPENLANE website. Customer’s continued participation in OPENLANE’s
          Services following the posting of any revisions to the Terms and
          Conditions shall constitute full acceptance of the modified terms.
        </li>
        <li>
          <b>Miscellaneous:</b> No waiver of the provisions hereof shall be
          effective unless in writing and signed by an authorized representative
          of OPENLANE. If any provision in these Terms and Conditions is held
          invalid or unenforceable, under any statute or court decision, or any
          governmental rule or regulation, the remainder of these Terms and
          Conditions shall remain effective. These Terms and Conditions shall
          bind the respective heirs, executors, administrators, successors and
          assigns of Customer and inure to the benefit of OPENLANE and its
          successors and assigns. Customer may not assign any of its rights
          under these Terms and Conditions without OPENLANE’s prior written
          consent, and any such attempt will be void. OPENLANE may assign its
          rights to any of its affiliates or subsidiaries, or to any successor
          in interest of any business associated with the Services. These Terms
          and Conditions, and other documents incorporated by reference herein,
          represent the entire agreement between you and OPENLANE with respect
          to the subject matter of these Terms and Conditions, and supersede and
          replace any other prior agreements.
        </li>
        <li>
          <b>Consent to Receive Communication.</b> Customer acknowledges that
          OPENLANE may use means other than the website to support and promote
          the Services. Without limiting the generality of the foregoing,
          Customer grants express consent to receive advertising offers and
          other information from OPENLANE on Customer’s behalf for any
          commercial or advertising purpose, using the telephone numbers
          (whether business, home, or mobile numbers), fax numbers, SMS codes,
          email addresses, and other contact information provided during the
          registration process, or at any other address, telephone number, fax
          number, SMS code, email address or contact point used in connection
          with Customer’s business. Customer hereby covenants not to sue and
          waive and release OPENLANE and its respective officers, employees,
          agents, representatives, affiliates, and customers from any and all
          rights, claims and causes of action arising out of the communications
          described in this paragraph, including, without limitation, claims
          that such communications violate Customer’s rights under the Telephone
          Consumer Protection Act, 47 U.S.C. § 227, or any federal or state law
          or regulation governing telemarketing, email marketing or fax
          marketing activities. Customer may terminate its consent by providing
          a written notice, executed by Customer, to the{" "}
          <a
            target="_blank"
            href="https://kar-privacy.my.onetrust.com/webform/220aba37-6609-45b9-bb38-d90c71628891/64a32440-709d-4d3c-9b6a-ff703c606b01"
          >
            Privacy Rights Portal
          </a>{" "}
          or using applicable “STOP” commends via text or “OPT OUT/Unsubscribe”
          mechanisms via email. Customer also consents to OPENLANE recording any
          phone conversation between you and an OPENLANE employee or contractor
          for the purpose of monitoring and providing customer service.
        </li>
        <li>
          <b>Links to Other Web Sites:</b> The Website and Services may contain
          links to third-party web sites or services that are not owned or
          controlled by OPENLANE. OPENLANE has no control over, and assumes no
          responsibility for the content, privacy policies, or practices of any
          third-party web sites or services. All information and materials
          displayed on or accessible via links from the Website are provided as
          a courtesy and for informational purposes only. OPENLANE does not
          warrant or guarantee the accuracy or reliability of third-party sites.
          Customer acknowledges and agrees that OPENLANE shall not be
          responsible or liable, directly, or indirectly, for any damage or loss
          caused or alleged to be caused by or in connection with use of or
          reliance on any such content, goods, or services available on or
          through any such third-party web site or services.
        </li>
        <li>
          <b>Storage of Vehicles.</b> Customer agrees that OPENLANE is not a
          bailee of vehicles unless OPENLANE signs a separate bailment or
          storage agreement with Customer. Customer shall not charge OPENLANE or
          the Seller any storage fees for any vehicle transported under this
          Agreement.
        </li>
      </ol>
    </p>
  );
}
