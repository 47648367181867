import { AppBar, Toolbar } from "@material-ui/core";
import { Color, Inline } from "@superdispatch/ui";
import styled from "styled-components";
import { TextBox } from "@superdispatch/ui-lab";
import { useTransporterProfile } from "./data/OrderTransportAPI";
import { LocalShippingRounded } from "@material-ui/icons";

const HeaderContainer = styled.div`
  margin: auto;
  width: 100%;
  max-width: 800px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoLink = styled.a`
  color: ${Color.Dark500};
  text-decoration: none;
  display: flex;
`;

const Logo = styled.div`
  background-color: ${Color.Silver200};
  color: #8f949e;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
`;

export function AppHeader() {
  const { data: transporter } = useTransporterProfile();

  return (
    <AppBar position="sticky">
      <Toolbar>
        <HeaderContainer>
          <Inline verticalAlign="center" space="small">
            {!!transporter?.personal_page_url && !!transporter?.logo_url ? (
              <LogoLink href={transporter.personal_page_url}>
                <img src={transporter.logo_url} alt="" width="40" height="40" />
              </LogoLink>
            ) : (
              <Logo>
                <LocalShippingRounded />
              </Logo>
            )}
            <TextBox variant="heading-3">Order Transport</TextBox>
          </Inline>
        </HeaderContainer>
      </Toolbar>
    </AppBar>
  );
}
