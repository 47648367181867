import React, { useEffect } from "react";
import { trackEvent } from "./services/AnalyticsService";
import { AppLayout } from "./AppLayout";
import { FormsProvider } from "@superdispatch/forms";
import { getFormErrors } from "./FormErrors";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";

export function App() {
  useEffect(() => {
    trackEvent("Customer Opened Order Transport", {
      window_width: window.screen.width,
      window_height: window.screen.height,
      window_width_physical: window.screen.width * window.devicePixelRatio,
      window_height_physical: window.screen.height * window.devicePixelRatio,
    });
  }, []);

  return (
    <AppLayout>
      <FormsProvider getFormErrors={getFormErrors}>
        <RouterProvider router={router} />
      </FormsProvider>
    </AppLayout>
  );
}
