import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@material-ui/core";
import { Button } from "@superdispatch/ui";
import { TermsConditionsContent } from "./TermsConditionsContent";

export interface TermsConditionsFormProps {
  open: boolean;
  onClose: () => void;
}

export function TermsConditionsDialog({
  open,
  onClose,
}: TermsConditionsFormProps) {
  return (
    <Dialog maxWidth="md" fullWidth={true} open={open} onClose={onClose}>
      <DialogTitle>Terms and Conditions</DialogTitle>
      <Divider />
      <DialogContent>
        <TermsConditionsContent />
        <Divider />
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={onClose}>
          Got it
        </Button>
      </DialogActions>
    </Dialog>
  );
}
