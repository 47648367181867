import { DescriptionItem, TextBox } from "@superdispatch/ui-lab";
import { Stack } from "@superdispatch/ui";
import { MonetizationOn } from "@material-ui/icons";

export function ManualQuoteView() {
  return (
    <Stack>
      <TextBox variant="heading-3">Transport Option</TextBox>

      <DescriptionItem icon={<MonetizationOn />}>Request Quote</DescriptionItem>
    </Stack>
  );
}
