import { isAPIError } from "./OrderTransportAPI";

export function formatQuotingServiceErrorMessage(error: Error) {
  let errorMessage = error.message;

  if (
    isAPIError(error) &&
    error.type === "QuotingAPIServiceError" &&
    error.details.length
  ) {
    errorMessage = error.details.map((item) => item.message).join(", ");
  }

  return errorMessage;
}
