import { createSvgIcon } from "shared/utils/IconUtils";

export const TrailerIcon = createSvgIcon(
  "PostingFilterDeliver",
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M7.34959 6.84821H10.6339C11.1344 6.84821 11.5722 6.44158 11.5722 5.90984C11.5722 4.5023 10.4462 3.37627 9.03865 3.37627H7.34959V6.84821ZM2.21987 5.90984C2.21987 6.4103 2.62649 6.84821 3.15823 6.84821H6.4425V3.37627H4.75345C3.3459 3.37627 2.21987 4.5023 2.21987 5.90984ZM12.667 10.2723H13.3324C13.8642 10.2723 14.3333 10.7102 14.3333 11.242V11.7896C14.3333 12.0399 14.1144 12.2588 13.8642 12.2588C13.6139 12.2588 13.395 12.0399 13.395 11.7896V11.242C13.395 11.2107 13.3637 11.1794 13.3324 11.1794H9.3827V11.023C9.3827 9.64673 8.25667 8.5207 6.8804 8.5207C5.50414 8.5207 4.3781 9.64673 4.3781 11.023V11.1794H2.4701C1.65685 11.1794 1 10.5225 1 9.70929V6.06624C1 3.81417 2.81417 2 5.06624 2H8.69457C10.9466 2 12.7608 3.81417 12.7608 6.06624V9.70929C12.7608 9.92824 12.7295 10.0846 12.667 10.2723ZM8.44436 11.023C8.44436 10.1592 7.74414 9.45908 6.88042 9.45908C6.0167 9.45908 5.31648 10.1592 5.31648 11.023C5.31648 11.8867 6.0167 12.587 6.88042 12.587C7.74414 12.587 8.44436 11.8867 8.44436 11.023Z"
    />
  </>,
  { viewBox: "0 0 16 16" }
);
