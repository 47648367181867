import { Form, FormikProvider } from "formik";
import { useEffect, useMemo, useState } from "react";
import { TextBox } from "@superdispatch/ui-lab";
import {
  Button,
  CheckboxField,
  Column,
  Columns,
  Inline,
  Stack,
  useSnackbarStack,
} from "@superdispatch/ui";
import {
  FormikDateField,
  FormikMaxLengthTextField,
  FormikTextField,
  useFormikEnhanced,
} from "@superdispatch/forms";
import { QuoteRequestDTO } from "../../data/QuoteRequestDTO";
import { useQuoteAPI } from "../../data/OrderTransportAPI";
import { QuoteDTO } from "../../data/QuoteDTO";
import {
  OrderPayloadDTO,
  orderPayloadSchema,
  PaymentCheckoutResponseDTO,
} from "../../data/OrderDTO";
import { FormikTelField } from "shared/forms/FormikTelField";
import {
  addLogContext,
  logInfo,
  logWarning,
} from "shared/services/MonitoringService";
import { Link, TextField } from "@material-ui/core";
import { trackOrderTransportEvents } from "../../data/OrderTransportAnalytics";
import { TermsConditionsDialog } from "./TermsConditionsDialog";
import { trackEvent } from "../../services/AnalyticsService";
import { OrderAttachmentsCard } from "./OrderAttachementsCard";
import { uploadAttachment } from "../../data/OrderAttaachmentAPI";

interface Props {
  quote: QuoteDTO;
  order: OrderPayloadDTO | null;
  quoteRequest: QuoteRequestDTO;
}

export function OrderDetailsForm({ order, quote, quoteRequest }: Props) {
  const [isAgree, $isAgree] = useState(false);
  const [isTermsConditionsOpen, $isTermsConditionsOpen] = useState(false);
  const [orderGuid, $orderGuid] = useState(order?.guid);
  const { submitOrder, paymentCheckout, updateOrder } = useQuoteAPI();
  const { addSnackbar } = useSnackbarStack();

  const initialValues = useMemo<OrderPayloadDTO>(() => {
    if (order) {
      return {
        ...order,
        quote_guid: quote.guid,
      };
    }

    const today = new Date().toISOString();
    return {
      attachments: [],
      quote_guid: quote.guid,
      contact_name: "",
      email: "",
      phone: "",
      company_name: "",
      pickup: {
        ready_date: today,
        contact_name: "",
        email: null,
        phone: "",
        notes: null,
      },
      delivery: {
        contact_name: "",
        email: null,
        phone: "",
        notes: null,
      },
    };
  }, [quote, order]);

  const formik = useFormikEnhanced<OrderPayloadDTO, PaymentCheckoutResponseDTO>(
    {
      initialValues,
      validationSchema: orderPayloadSchema,
      onSubmit: async (values) => {
        addLogContext("order_details", values);

        const { guid } = orderGuid
          ? await updateOrder(orderGuid, values)
          : await submitOrder(values);

        $orderGuid(guid);

        for (const attachment of values.attachments) {
          if (!attachment.url && attachment.file instanceof File) {
            await uploadAttachment(guid, attachment.file);
          }
        }

        addLogContext("order_guid", { guid });
        return paymentCheckout(guid);
      },
      onSubmitSuccess({ checkout_session_url }, values) {
        trackOrderTransportEvents({
          name: "Customer Proceeded to Payment",
          orderDetails: values,
        });
        logInfo("Submitted order");
        window.location.href = checkout_session_url;
      },
      onSubmitFailure(error) {
        logWarning("Failed to submit order", { error });
        addSnackbar(error.message || "Failed to submit order", {
          variant: "error",
        });
      },
    }
  );

  useEffect(() => {
    $orderGuid(order?.guid);
  }, [order]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <TermsConditionsDialog
          open={isTermsConditionsOpen}
          onClose={() => {
            $isTermsConditionsOpen(false);
          }}
        />
        <Stack space="small">
          <TextBox variant="heading-2">Order Details</TextBox>

          <Stack space="large">
            <Columns space="large" collapseBelow="tablet">
              <Column>
                <Stack space="small">
                  <FormikTextField
                    name="company_name"
                    label="Company Name"
                    fullWidth={true}
                  />

                  <FormikTextField
                    name="contact_name"
                    label="Contact Name"
                    fullWidth={true}
                  />
                </Stack>
              </Column>

              <Column>
                <Stack space="small">
                  <FormikTextField
                    name="email"
                    label="Email"
                    fullWidth={true}
                  />

                  <FormikTelField name="phone" label="Phone" fullWidth={true} />
                </Stack>
              </Column>
            </Columns>

            <Columns space="large" collapseBelow="tablet">
              <Column>
                <Stack space="small">
                  <TextBox variant="heading-4">Pickup Details</TextBox>

                  <FormikTextField
                    name="pickup.contact_name"
                    label="Contact Name"
                    fullWidth={true}
                  />

                  <FormikTelField
                    name="pickup.phone"
                    label="Phone"
                    fullWidth={true}
                  />

                  <FormikTextField
                    fullWidth={true}
                    name="pickup.email"
                    label={
                      <TextBox>
                        Email <TextBox color="secondary">(Optional)</TextBox>
                      </TextBox>
                    }
                  />

                  <TextField
                    fullWidth={true}
                    disabled={true}
                    label="Pickup Address"
                    value={quoteRequest.pickup.address}
                  />

                  <FormikMaxLengthTextField
                    fullWidth={true}
                    multiline={true}
                    maxLength={255}
                    name="pickup.notes"
                    label={
                      <Inline space="xxsmall">
                        <TextBox>Pickup Notes</TextBox>
                        <TextBox color="secondary">(Optional)</TextBox>
                      </Inline>
                    }
                  />

                  <FormikDateField
                    name="pickup.ready_date"
                    label="Ready for Pickup On"
                    fullWidth={true}
                    CalendarProps={{
                      disabledDays: ({ dateValue }) =>
                        dateValue < new Date().setHours(0),
                    }}
                  />
                </Stack>
              </Column>

              <Column>
                <Stack space="small">
                  <TextBox variant="heading-4">Delivery Details</TextBox>

                  <FormikTextField
                    name="delivery.contact_name"
                    label="Contact Name"
                    fullWidth={true}
                  />

                  <FormikTelField
                    name="delivery.phone"
                    label="Phone"
                    fullWidth={true}
                  />

                  <FormikTextField
                    fullWidth={true}
                    name="delivery.email"
                    label={
                      <TextBox>
                        Email <TextBox color="secondary">(Optional)</TextBox>
                      </TextBox>
                    }
                  />

                  <TextField
                    fullWidth={true}
                    disabled={true}
                    label="Delivery Address"
                    value={quoteRequest.delivery.address}
                  />

                  <FormikMaxLengthTextField
                    multiline={true}
                    fullWidth={true}
                    maxLength={255}
                    name="delivery.notes"
                    label={
                      <Inline space="xxsmall">
                        <TextBox>Delivery Notes</TextBox>
                        <TextBox color="secondary">(Optional)</TextBox>
                      </Inline>
                    }
                  />
                </Stack>
              </Column>
            </Columns>

            <OrderAttachmentsCard orderGuid={order?.guid} />

            <Stack space="small">
              <CheckboxField
                checked={isAgree}
                onChange={(_, value) => {
                  $isAgree(value);
                }}
                label={
                  <>
                    I agree to{" "}
                    <Link
                      onClick={(event) => {
                        $isTermsConditionsOpen(true);
                        trackEvent("Customer Opened Terms and Condition");
                        event.preventDefault();
                      }}
                    >
                      Terms and Conditions.
                    </Link>
                  </>
                }
              />

              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={!isAgree}
                isLoading={formik.isSubmitting}
              >
                Proceed to Payment
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
