import styled from "styled-components";
import { Color, Stack, useSnackbarStack } from "@superdispatch/ui";
import { useEffect, useState } from "react";
import { TransportDetailsForm } from "./TransportDetailsForm";
import { TransportOptionsForm } from "./TransportOptionsForm";
import { QuoteDTO, QuotesResponseDTO } from "../../data/QuoteDTO";
import { TransportDetailsView } from "./TransportDetailsView";
import { QuoteRequestDTO } from "../../data/QuoteRequestDTO";
import { TransportOptionsView } from "./TransportOptionsView";
import { OrderDetailsForm } from "./OrderDetailsForm";
import { OrderDetailsView } from "./OrderDetailsView";
import { PaymentView } from "./PaymentView";
import { ManualQuoteForm } from "./ManualQuoteForm";
import { ManualQuoteView } from "./ManualQuoteView";
import { useSearchParams, useNavigate } from "react-router-dom";
import { isAPIError, useOrder, useQuotes } from "../../data/OrderTransportAPI";
import { OrderPayloadDTO } from "../../data/OrderDTO";

const Container = styled.div`
  flex: 1;
  max-width: 800px;
`;

const Content = styled.div`
  padding: 32px;

  background-color: #fff;
  border-radius: 3px;
  border: 1px solid ${Color.Silver400};
`;

export function OrderTransportPage() {
  const navigate = useNavigate();
  const { addSnackbar } = useSnackbarStack();
  const [searchParams] = useSearchParams();
  const orderGUIDParam = searchParams.get("order_guid");
  const quoteRequestGUIDParam = searchParams.get("quote_request_guid");

  const [quoteRequest, $quoteRequest] = useState<QuoteRequestDTO | null>(null);
  const [quotes, $quotes] = useState<QuotesResponseDTO | null>(null);
  const [activeQuote, $activeQuote] = useState<QuoteDTO | null>(null);
  const [order, $order] = useState<OrderPayloadDTO | null>(null);
  const [step, $step] = useState<
    "transport_details" | "quote" | "order_details"
  >("transport_details");

  const { data: remoteOrder, error: remoteOrderError } =
    useOrder(orderGUIDParam);
  const { data: remoteQuotes, error: remoteQuotesError } = useQuotes(
    quoteRequestGUIDParam
  );

  useEffect(() => {
    if (remoteQuotes) {
      $quoteRequest(remoteQuotes.quote_request);
      $quotes(remoteQuotes);
      $step("quote");
    }
  }, [remoteQuotes]);

  useEffect(() => {
    if (remoteOrder) {
      $quoteRequest(remoteOrder.quote_request);
      $activeQuote(remoteOrder.quote);
      $quotes({
        quote_request: {
          guid: "000", // todo
          ...remoteOrder.quote_request,
        },
        quotes: [remoteOrder.quote],
      });
      $order(remoteOrder);
      $step("order_details");
    }
  }, [remoteOrder]);

  useEffect(() => {
    if (remoteOrderError) {
      addSnackbar(remoteOrderError.message, { variant: "error" });
    }
  }, [addSnackbar, remoteOrderError]);

  useEffect(() => {
    const errorType = isAPIError(remoteQuotesError) && remoteQuotesError?.type;

    if (errorType === "QuoteExpiredError") {
      navigate("/quote-expired");
    } else if (errorType === "QuoteAlreadyPaidError") {
      navigate("/order-booked");
    } else if (remoteQuotesError) {
      addSnackbar(remoteQuotesError.message, { variant: "error" });
    }
  }, [addSnackbar, remoteQuotesError, navigate]);

  return (
    <Container>
      <Stack space="small">
        <Content>
          {step === "transport_details" ? (
            <TransportDetailsForm
              quoteRequest={quoteRequest}
              onSubmitSuccess={(quotesResult) => {
                $quotes(quotesResult);
                $quoteRequest(quotesResult.quote_request);
                $step("quote");
              }}
            />
          ) : quoteRequest ? (
            <TransportDetailsView
              quoteRequest={quoteRequest}
              onEdit={() => {
                $step("transport_details");
              }}
            />
          ) : null}
        </Content>

        {quoteRequest && quotes?.quotes.length === 0 && (
          <Content>
            {step === "quote" ? (
              <ManualQuoteForm quoteRequestGuid={quotes.quote_request.guid} />
            ) : (
              <ManualQuoteView />
            )}
          </Content>
        )}

        {quotes && quotes.quotes.length > 0 && (
          <>
            <Content>
              {step === "quote" ? (
                <TransportOptionsForm
                  quotes={quotes}
                  onSubmit={(quoteValue) => {
                    $activeQuote(quoteValue);
                    $step("order_details");
                  }}
                />
              ) : (
                <TransportOptionsView quotes={quotes} />
              )}
            </Content>

            <Content>
              {activeQuote && quoteRequest && step === "order_details" ? (
                <OrderDetailsForm
                  order={order}
                  quote={activeQuote}
                  quoteRequest={quoteRequest}
                />
              ) : (
                <OrderDetailsView />
              )}
            </Content>

            <Content>
              <PaymentView />
            </Content>
          </>
        )}
      </Stack>
    </Container>
  );
}
