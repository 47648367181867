import { FieldArray, getIn, useField, useFormikContext } from "formik";
import {
  FormikCheckboxField,
  FormikTextField,
  FormikTextFieldProps,
} from "@superdispatch/forms";
import { Inline, Stack } from "@superdispatch/ui";
import { IconButton } from "@material-ui/core";
import { Delete, Add } from "@material-ui/icons";
import { Button } from "@superdispatch/ui-lab";
import styled from "styled-components";
import { QuoteRequestVehicleDTO } from "../../data/QuoteRequestDTO";

const FieldsContainer = styled.div`
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: 80px 114px 176px 124px 34px;

  @media (max-width: 1340px) {
    grid-row-gap: 8px;
    grid-template-columns: repeat(3, minmax(80px, 1fr));

    & :nth-child(2) {
      grid-column: 2 / span 3;
    }
    & :nth-child(3) {
      grid-column: 1 / span 2;
    }

    & :nth-child(5) {
      grid-row: 3;
    }
  }
`;

function VehicleItemField({
  index,
  name,
  onRemove,
  showDivider,
}: {
  index: number;
  showDivider: boolean;
  name: string;
  onRemove: () => void;
}) {
  return (
    <Inline space="small" verticalAlign="center">
      <FormikTextField
        name={`${name}.vin`}
        style={{ marginTop: -20, width: 250 }}
        label={showDivider ? `VIN ${index + 1}` : "VIN"}
      />

      <FormikCheckboxField
        name={`${name}.is_inoperable`}
        label="Non-drivable"
      />

      {showDivider && (
        <IconButton onClick={onRemove}>
          <Delete />
        </IconButton>
      )}
    </Inline>
  );
}

export function VehiclesField({ name }: { name: string }) {
  const { isSubmitting } = useFormikContext();

  const [{ value = [{ vin: null, is_inoperable: false }] }] =
    useField<QuoteRequestVehicleDTO[]>(name);

  return (
    <FieldArray name={name}>
      {({ remove, push }) => {
        return (
          <Stack space="small">
            <Stack space="medium">
              {value.map((item, index) => (
                <VehicleItemField
                  key={index}
                  index={index}
                  showDivider={value.length > 1}
                  name={`${name}.${index}`}
                  onRemove={() => {
                    remove(index);
                  }}
                />
              ))}
            </Stack>

            <Button
              variant="text"
              startIcon={<Add />}
              disabled={isSubmitting}
              onClick={() => {
                push({ vin: "", is_inoperable: false });
              }}
            >
              Add Vehicle
            </Button>
          </Stack>
        );
      }}
    </FieldArray>
  );
}
