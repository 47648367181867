import { useEffect } from "react";
import { logError } from "shared/services/MonitoringService";
import { parse, stringify } from "qs";

export type URLSearchQuery = Record<string, string | undefined>;
export type URLSearchQueryInit = Record<string, any>;

export function parseSearchQuery(search: string): URLSearchQuery {
  return parse(search, {
    ignoreQueryPrefix: true,
  }) as URLSearchQuery;
}

export function stringifySearchQuery(query: URLSearchQueryInit): string {
  return stringify(query, {
    skipNulls: true,
    sort: (a: string, b: string) =>
      a.toLowerCase().localeCompare(b.toLowerCase()),
  });
}

{
  const segmentKey =
    import.meta.env.VITE_APP_TARGET === "production"
      ? "v49CUaPqA1DKExQi9E7BWGVg2Yzm8wC4"
      : import.meta.env.VITE_APP_TARGET === "staging"
      ? "rlwwFGMas8AMXtaul6qU6rTpUIPwznXN"
      : null;

  if (segmentKey) {
    tryRun(() => {
      analytics.load(segmentKey);
      analytics.page();
    });
  }
}

function tryRun(fn: () => void): void {
  if (typeof analytics != "undefined") {
    try {
      fn();
      return;
    } catch (error) {
      logError(error, "Analytics");
    }
  }
}

export function onAnalyticsReady(fn: () => void): void {
  tryRun(() => {
    analytics.ready(() => {
      fn();
    });
  });
}

export function resetAnalytics(): void {
  tryRun(() => {
    analytics.reset();
  });
}

export function identifyUser() {
  analytics.identify();
  analytics.page();
}

export function getUserId() {
  return analytics.user().id();
}

export function getAnonymousId() {
  return analytics.user().anonymousId();
}

function getUTMs() {
  const query = parseSearchQuery(window.location.search);
  const tags: Record<string, unknown> = {};

  Object.keys(query).forEach((key) => {
    if (key.startsWith("utm_")) {
      tags[key] = query[key];
    }
  });

  return tags;
}

export function trackEvent(
  eventName: string,
  data?: Record<string, string | number | undefined | boolean | null>,
  callback?: () => void
) {
  const queryUtms = getUTMs();

  const properties = {
    ...queryUtms,
    ...data,
    source: "Order Transport Web",
    utm_source:
      data?.utm_source || queryUtms.utm_source || "Order Transport Web",
  };

  tryRun(() => {
    analytics.track(eventName, properties, callback);
  });

  if (import.meta.env.MODE !== "production") {
    // eslint-disable-next-line no-console
    console.info('Analytics: "%s": %O', eventName, properties);
    callback?.();
  }
}

export function useTrackEvent(name: string) {
  useEffect(() => {
    trackEvent(name);
  }, [name]);
}
