import { InferType, array, number, object, string } from "yup";
import { quoteRequestSchema } from "./QuoteRequestDTO";

export type QuoteDTO = InferType<typeof quoteSchema>;
export const quoteSchema = object({
  price: number().defined(),
  guid: string().defined(),
  expires_at: string().defined(),
});

export type QuotesResponseDTO = InferType<typeof quotesResponseSchema>;
export const quotesResponseSchema = object({
  quotes: array(quoteSchema).defined(),
  quote_request: quoteRequestSchema.shape({ guid: string().defined() }),
});
